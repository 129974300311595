import { Col, Row, Input, Button, message, Form, notification } from 'antd'
import './Login.css'
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import axios from 'axios';
import { actionTypes } from '../Reducer/stateProvider';
import { BaseUrl } from '../../Constants/Constant';

import { useStateValue } from '../Reducer/reducer';

const Login = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [EmailStatus, setEmailStatus] = useState('');
  const [pwdStatus, setPwdStatus] = useState('');

  // const [messageApi, contextHolder] = message.useMessage();
  const [
    { },
    dispatch,
  ] = useStateValue();
  // const info = (val) => {
  //   messageApi.open({
  //     type: 'error',
  //     content: val,
  //   });
  // };

  console.log(email, 'Email')

  const navigate = useNavigate();

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIconWarning = (type) => {
    api['warning']({
      message: "Warning!!!",
      description:
        type,
    });
  };


  //=================== location API ================//

  const geolocationAPI = navigator.geolocation;
  const getUserCoordinates = () => {
    // debugger;
    if (!geolocationAPI) {
      console.log("Geolocation API is not available in your browser!");
    } else {
      console.log('gets in here')
      geolocationAPI.getCurrentPosition(
        (position) => {
          const { coords } = position;
          let lattitude = parseFloat(coords.latitude);
          let longtitude = parseFloat(coords.longitude);
          // setLat(coords.latitude);
          // setLong(coords.longitude);

          console.log(coords.latitude, coords.longitude, 'llllllll')


          // const apiKey = 'AIzaSyAn4guV9udrnZ0WVfxXWEr4ClB16ho2E-0';
          const apiKey = 'AIzaSyDOZ3jvBxGeA865SjZZY79xY38mkkilPiU';

          const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lattitude},${longtitude}&key=${apiKey}`;

          fetch(geocodeUrl)
            .then(response => response.json())
            .then(data => {
              console.log(data, 'locationData')
              if (data.results && data.results.length > 0) {
                const city = getCityName(data.results[0]);
                console.log('City:', city);
              }
            })
            .catch(error => {
              console.log('Geocoding error:', error);
            });

        },
        (error) => {
          console.log("Something went wrong getting your position!");
        }
      );
    }
  };



  useEffect(() => {
    getUserCoordinates();
  }, [])
  

  const getCityName = result => {
    for (let i = 0; i < result.address_components.length; i++) {
      const component = result.address_components[i];
      if (component.types.includes('locality') || component.types.includes('political')) {
        return component.long_name;
      }
    }
    return '';
  };

  // ======================================================== //

  const openNotificationWithIconSuccess = (type) => {
    console.log('getsSuccess')
    api['success']({
      message: "Success!!!",
      description:
        type,
    });
  };


  const handleLogin = () => {
    
    if (pwdStatus === "success") {
    


      // sessionStorage.setItem("IsAuthenticated", "false");
      localStorage.setItem("Email", email);


      let body = {
        email: email.toLowerCase(),
        password: password
      }

      axios.post(`${BaseUrl}/login_email`, body)
        .then((res) => {
          
          console.log(res.data, 'loginRes')

          if (res.data.status == 401) {
            openNotificationWithIconWarning('Please enter valid password');
            // info('Please enter valid password');
            setPwdStatus('error');
          } else if (res.data.status == 400) {
            openNotificationWithIconWarning(res.data.response);
            // info(res.data.response);
            setEmailStatus('error');
          } else if (res.data.status == 200) {
    localStorage.setItem("CompanyID",res.data.company_id );
            localStorage.setItem("UserID", res.data.user_id);
            //remove after demo
            // let url = `${BaseUrl}/select_plan?user_id=${res.data.user_id}&plan_name=Corporate&price=1000`
            // axios
            //   .post(url)
            //   .then((res) => {
            //     console.log(res,"planupdates")
            //   })
            if(res.data.role_id === 1){
              openNotificationWithIconSuccess('Logged in as Super Admin');
              setTimeout(() => {
                navigate("/userSetting");
              }, 1500)
            }
            if(res.data.role_id === 2){
              openNotificationWithIconSuccess('Logged in as Admin');
              setTimeout(() => {
                navigate("/userSetting");
              }, 1500)
            }
            if(res.data.role_id === 3){
              openNotificationWithIconSuccess('Logged in as Content Editor');
              setTimeout(() => {
                navigate("/my-chatbots");
              }, 1500)
            }
            if(res.data.role_id === 4){
              openNotificationWithIconSuccess('Logged in as Content Reviewer');
              setTimeout(() => {
                navigate("/my-chatbots");
              }, 1500)
            }
            if(res.data.role_id === 5){
              openNotificationWithIconSuccess('Logged in as Content Approver');
              setTimeout(() => {
                navigate("/my-chatbots");
              }, 1500)
            }
            if(res.data.role_id === 6){
              openNotificationWithIconSuccess('Logged in as Agent');
              setTimeout(() => {
                navigate("/my-chatbots");
              }, 1500)
            }
            if(res.data.role_id === 7){
              openNotificationWithIconSuccess('Logged in as User - Internal');
            }
            if(res.data.role_id === 8){
              openNotificationWithIconSuccess('Logged in as User - External');
              setTimeout(() => {
                navigate("/my-chatbots");
              }, 1500)
            }
            if(res.data.role_id === 9){
              openNotificationWithIconSuccess('Logged in as User - External Auth');
              setTimeout(() => {
                navigate("/my-chatbots");
              }, 1500)
            }
            
    localStorage.setItem("Role",res.data.role_id );
            dispatch({
              type: actionTypes.SET_ROLE,
              role : res.data.role_id
            })
            localStorage.setItem("IsAuthenticated", "true");
            dispatch({
              type: actionTypes.SET_ISAUTHENTICATED,
              IsAuthenticated: "true"
            })
            
          
          } else if (res.data.status == 402) {
            openNotificationWithIconWarning('Please fill all the fields');
            // info('Please fill all the fields')
            setPwdStatus('error');
            localStorage.setItem("IsAuthenticated", "false");
            setEmailStatus('error')
          }
          // else if( !email ){
          //   setEmailStatus('error');
          // }else if( !password ){
          //   setPassword('error')
          // }
          else {
            setEmailStatus('');
            setPwdStatus('');
          }

        })
        .catch(( err ) => {
          console.log(err,'loginError')
        })
    }
    else {
      openNotificationWithIconWarning('Please enter valid password');
      // info('Please enter valid password');
    }
  }

  const handleSignup = () => {
    navigate("/signup")
  }

  const handleForgotPass = () => {
    navigate("/ForgotPassword")
  }


  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Email validation regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(value);

    // Update email status based on validation
    setEmailStatus(isValidEmail ? 'success' : 'error');
  };
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    // Password validation regex pattern
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
    const isValidPassword = passwordRegex.test(value);

    // Update password status based on validation
    setPwdStatus(isValidPassword ? 'success' : 'error');
    console.log(isValidPassword, "isValidPassword")
  };

  return (
    <div>
      {contextHolder}
      <Row>
        <Col style={{ margin: 0 }}
          // span={{ xs: 8, sm: 16, md: 24, lg: 12 }} 
          offset={0}
        >
          <img src='/Home/Precium-logo.png' className='headerLogoOnly' />
          <img src='/login/sidePane.svg' className='loginImage' alt='SidePaneImage' />
        </Col>
        <Col
          //  span={{ xs: 8, sm: 16, md: 24, lg: 12 }}
          offset={2}>
          <div className='logindiv'>
            <h1 className='LoginH1'>
              Login
            </h1>

            <Input
              onKeyDown={(e) => {
                if(e.key === 'Enter'){
                  document.getElementById('passwordInputField').focus();
                }
              }}
              prefix={<MailOutlined className='mailIcon' />}
              className='loginInput'
              placeholder='Email ID'
              onChange={handleEmailChange}
              status={!email ? EmailStatus : ''}
            />
            <Form.Item className='PasswordForm' hasFeedback validateStatus={pwdStatus}>

              <Input.Password
              id='passwordInputField'
                onKeyDown={(e) => {
                  if(e.key == 'Enter') handleLogin();
                }}
                prefix={<LockOutlined className='mailIcon' />}
                className='loginInput'
                placeholder='Password'
                type='password'
                onChange={handlePasswordChange}
                status={!password ? pwdStatus : ''}
              />
            </Form.Item>


          </div>
          <div className='forgotpassDiv'>
            <Button
              onClick={handleForgotPass}
              className='forgotpassword' size='small' type='link'>
              Forgot Password?
            </Button>
          </div>
          <div>
            <Row>
              <Button
                onClick={handleLogin}
                className='LoginButton'>
                Login
              </Button>

            </Row>
            <p className='ortag'>------------ or ------------</p>
            <Row>
              <Button
                onClick={handleSignup}
                className='signUpButton'>
                Signup
              </Button>

            </Row>

          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Login
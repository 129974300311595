import React, { useState, useEffect } from 'react'
import Headers from '../Header/Headers'
import { Space, Table, Tag, Row, Col, Card, Button, Modal, Form, Input, Select, Tooltip, message, notification } from 'antd';
import Footer from '../Footer/Footer'
import { DeleteOutlined, LockOutlined, QuestionCircleOutlined, SaveOutlined } from '@ant-design/icons';
import './UserSettings.css'
import { useNavigate } from 'react-router';
import axios from 'axios';
import { useStateValue } from '../Reducer/reducer';
import { actionTypes } from '../Reducer/stateProvider'
import { BaseUrl } from '../../Constants/Constant'
import { Link } from 'react-router-dom'

const UserSettings = () => {
  const [
    { },
    dispatch,
  ] = useStateValue();
  const UserID = localStorage.getItem("UserID");
  const [plan, setplan] = useState("")
  const [Roles, setRoles] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [FirstName, setFirstName] = useState("")
  const [LastName, setLastName] = useState("")
  // const [messageApi, contextHolder] = message.useMessage();
  const [EmailStatus, setEmailStatus] = useState('');
  const [Email, setEmail] = useState("")
  const [Password, setPassword] = useState("")
  const [pwdStatus, setPwdStatus] = useState('');
  const [SelectedRole, setSelectedRole] = useState("user")
  const [LoadTable, setLoadTable] = useState(false)
  const [OpenModalDel, setOpenModalDel] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [data, setData] = useState([])
  const [Load, setLoad] = useState(true)
  const [Loading, setLoading] = useState(false)

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIconSuccess = (type) => {
    console.log('getsSuccess')
    api['success']({
      message: "Success!!!",
      description:
        type,
    });
  };

const openNotificationWithIconError = (type) => {
    api['error']({
      message: "Oops!!!",
      description:
        type,
    });
  };

  const navigate = useNavigate();
  useEffect(() => {
    let URL = `${BaseUrl}/role_details`
    axios
      .post(URL)
      .then((res) => {
        if (res.data.status === 200) {
          setRoles(res.data.response)

        }
        console.log(res, "chan")
      })
      
      
  }, [])
  
  const  Role = localStorage.getItem("Role")
  
  console.log(parseInt( Role),"Role")
  useEffect(() => {
    if(parseInt(Role) > 2 ){
      navigate("/my-chatbots");
    }

  }, [Role])

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    // Password validation regex pattern
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
    const isValidPassword = passwordRegex.test(value);

    // Update password status based on validation
    setPwdStatus(isValidPassword ? 'success' : 'error');
    console.log(isValidPassword, "isValidPassword")
  };

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name'
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name'
    },
    {
      title: 'Role',
      key: 'tags',
      dataIndex: 'tags',
      render: (_, record,{ tags }) => (
        
        <>


          <Select style={{ minWidth: 100,width:150 }} defaultValue={record.role}onSelect={(e)=>setSelectedRole(e)} options={Roles}>
        
          </Select>


        </>
      ),
    },
  
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <DeleteOutlined style={{ color: 'red' }} onClick={(e) => warning(record) } />
          <SaveOutlined style={{ color: 'green' }} onClick={(e) =>info(record)} />
        </Space>
      ),
    },
  ];
  const CompanyID = localStorage.getItem("CompanyID")
 


  useEffect(() => {

    let detailsAPI = `${BaseUrl}/user_affliations/user_details`
    
    let body = {
      "company_id":CompanyID
    }
   axios
   .post(detailsAPI,body)
   .then((res) => {
    setLoading(true)
    console.log(res.data.result,"companycga")
    if(res.data.status == 200){
      setData(res.data.result)

      if(res.data.result.length < 1 ){

        setIsDisabled(true)
        
      }else{
        setIsDisabled(false)
        dispatch({
          type: actionTypes.SET_ISAUTHENTICATED,
          IsAuthenticated: "true"
        })
      }
     if(LoadTable == true ){
        if(Load != false){


      if(res.data.result.length == 1 ){
        //  success('Please re-login');
         openNotificationWithIconSuccess('Please re-login');
    setTimeout(() => {
      
      navigate('/Login')
    }, 1000)
      }
    }
  }
    }  
   
   })

   
  }, [LoadTable])
 
  
  // const success = (e) => {
  //   messageApi.open({
  //     type: 'success',
  //     content: e,
  //   });
  // };
  // const error = (e) => {
  //   messageApi.open({
  //     type: 'error',
  //     content: e,
  //   });
  // };
  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    // Email validation regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(value);

    // Update email status based on validation
    setEmailStatus(isValidEmail ? 'success' : 'error');
  };

  const showModal = () => {
   
    setIsModalOpen(true);
  };
  const handleOk = () => {

    if (EmailStatus == 'success') {
      if (pwdStatus === 'success') {

        setLoadTable(false)

        let userAffiliationsURL = `${BaseUrl}/user_affiliation`
        let CompanyID = localStorage.getItem("CompanyID")
        let body = {
          "company_id": CompanyID,
          "first_name": FirstName,
          "last_name": LastName,
          "email": Email.toLowerCase(),
          "password": Password,
          "role": SelectedRole,
          "otp_verified": 0
        }
        axios
          .post(userAffiliationsURL, body)
          .then((res) => {
            setLoadTable(true)
            console.log(res, "chanres")
            if(res.data.status === 200){

              
              localStorage.setItem("UserID", res.data.user_id);
              // success(res.data.response);
              openNotificationWithIconSuccess(res.data.response);
             setLoad(true)
              setIsModalOpen(false);
              setEmail('')
              setFirstName('')
              setLastName('')
              setPassword('')
            }
            else{
setLoad(false)
            // error(res.data.response);
            openNotificationWithIconError(res.data.response);
            }
          })

        // setIsModalOpen(false);
      }
      else {
        openNotificationWithIconError('Passwrod is not valid');
        // error('Passwrod is not valid');
      }
    }
    else {
      openNotificationWithIconError('Email is not valid');
      // error('Email is not valid');
    }
  };
    const handleCancel = () => {
    setEmail("")
    setFirstName("")
    setLastName("")
    setPassword("")
    setIsModalOpen(false);
  };
  const HandleDel = () => {
    setOpenModalDel(true)
  }
  useEffect(() => {
    let url = `${BaseUrl}/display_userplan_details`
    let body = {
      "company_id": CompanyID
    }
    axios
      .post(url, body)
      .then((res) => {
        if (res.data.status == 200) {

          setplan(res.data.plan_name)
        }
        console.log(res, "ressplan")
      })
      .catch((err)=> {
        console.log(err)
      })
  }, [])

  const warning = (e) => {
    console.log(e,"eventwarning")
    Modal.warning({
      title: 'Do you want to delete this user?',
      content: ('This action deletes the User'),
    onOk ( )  {
      if(data.length <= 1 ){
        openNotificationWithIconError('you cannot delete all users');
        // error('you cannot delete all users')
      }else{

        setLoadTable(false)
      let deleteURL =`${BaseUrl}/delete_user`
      let deleteBody = {
        "user_id": e.user_id
      }
      axios
      .post(deleteURL,deleteBody)
      .then((res) => {
        setLoadTable(true)
        if(res.data.status == 200){
          openNotificationWithIconSuccess(res.data.response);
          // success(res.data.response);

        }
        else{
          openNotificationWithIconError(res.data.response);
          // error(res.data.response);
        }
        console.log(res,"res")
      })
    }
     }
    });

  };
  const info = (e) => {
    console.log(e,"event")
    Modal.info({
      title: 'Save Changes?',
      content: ('Do you want to save this change'),
      onOk() {
        let updateURL = `${BaseUrl}/update_user_roles`
        let body = {
          user_id: e.user_id,
          roles: SelectedRole,
          email:e.email
        }
        axios
        .post(updateURL,body)
        .then((res) => {
          setLoadTable(true)
        if(res.data.status == 200){
          openNotificationWithIconSuccess(res.data.response);
          // success(res.data.response);
         

        }
        else{
          openNotificationWithIconError(res.data.response);
          // error(res.data.response);
        }
        })
       }
    });

  };
  const HandlePlan=() => {
    console.log(data,'chan')
    if(data.length < 1 ){
      message.error('Add a User to select plan')
    }
    else{

      navigate("/pricing");
    }
  }
  
  return (
    <div className='userSetting-main'>
      <Headers disabled={isDisabled}  />
      {contextHolder}
      <Modal title="Add Members" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Add user
          </Button>,

        ]}
      >
        <Form
        layout="vertical">
          <Row>
            <Col span={11}>
            <Form.Item label="First Name" style={{ margin:0 }}>
            <Input placeholder="First Name"
            value={FirstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Item>
            </Col>
            <Col span={11} offset={1}>
            <Form.Item label="Last Name" style={{  margin:0 }}>
            <Input placeholder="Last Name"
            value={LastName}
              onChange={(e) => setLastName(e.target.value)} />
          </Form.Item>
            </Col>
          </Row>
          
          
          <Form.Item label="Role" style={{  margin:0 }}>
            <Select style={{ minWidth: 100 }} onChange={(e) => setSelectedRole(e)} defaultValue='User' options={Roles}  />
          </Form.Item>
          <Form.Item label="Email" style={{  margin:0 }}>
            <Input placeholder="Email"
            value={Email}
              status={!Email ? EmailStatus : ''}
              onChange={handleEmailChange} />
          </Form.Item>
          <Form.Item label="password" hasFeedback validateStatus={pwdStatus} style={{  margin:0 }}>
            <Input.Password
              // prefix={<LockOutlined className='mailIcon' />}
              // status={!Password ? "" : "error"}
              value={Password}
              onChange={handlePasswordChange}
              placeholder="Password" type='password' />


            {pwdStatus === 'error' && (
              <Tooltip title="Password must contain 8 characters, one lowercase letter, one digit, and one special character">
                <QuestionCircleOutlined className="infoIcon" />
              </Tooltip>
            )}
          </Form.Item>

        </Form>
      </Modal>
      <div className='UserSettingsMainDiv'>
        {
          Loading === true ?

        
        <Row>
          <Col  offset={6}>
            <h2 style={{ marginBottom: 10 }}>
              Organization Members
            </h2>
            <Card>
              <h3 className='currentPlan'>
                Current Plan : {plan ?  <code style={{ color: '#b670d0' }}>{plan}</code> :
                <Button className='selectPlan' onClick={HandlePlan}>Select Plan</Button>}
              </h3>
              <Button className='AddUsers ' onClick={showModal}>
                Add Members
              </Button>

              <Table columns={columns} dataSource={data} />
              {/* <p style={{padding:10}}>Don't need to add members? <Link to='/' ><span style={{ color: '#b670d0' }} >skip</span></Link></p> */}
            </Card>

          </Col>

        </Row>
        :
        <div className="loaderDiv">
        <img src="/loader/tail-spin.svg" className="loaderImage" />
        </div>
}
      </div>
      <Footer />
    </div>
  )
}

export default UserSettings
import React, { useEffect, useState } from 'react'
import Headers from '../Header/Headers'
import Footer from '../Footer/Footer'
import './Landing.css'

import { Col, Row, Steps, notification, Modal } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { useStateValue } from '../Reducer/reducer'
import { actionTypes } from '../Reducer/stateProvider'
import { BaseUrl } from '../../Constants/Constant'
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import axios from 'axios'

const Landing = () => {

    const [
        { IsAuthenticated },
        dispatch,
    ] = useStateValue();
    const UserID = localStorage.getItem("UserID");
    const navigate = useNavigate();

    const [api, contextHolder] = notification.useNotification();

    const stepItems = [
        {
            title: 'You provide the system with data',
            description: 'It could be a website, pdf files, and soon you will have the option to integrate with many more',
        },
        {
            title: 'We train our AI on this knowledge',
            description: 'It might take minutes or hours, depend on how large is your data to create the embeddings',
        },
        {
            title: 'You integrate the chatbot on your website',
            description: 'That is it! we will provide you with a javascript code to put it on your website',
        },
    ];

    const stepItem = [
        {
            title: 'Choose type'
        },
        {
            title: 'Source',
        },
        {
            title: 'Settings',
        },
    ];

    const openNotificationWithIconError = (type) => {
        api['error']({
            message: "Oops!!!",
            description:
                type,
        });
    };

    // useEffect(() => {
    //     window.location.reload(false);
    // },[])

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const handleCreateBot = () => {

        setIsModalOpen(true);
    }

    const handleWebsiteCreateBot = () => {

        let CreateBotURL = `${BaseUrl}/Bot_ID`
        axios
            .post(CreateBotURL)
            .then((res) => {
                if (res.data.response) {
                    localStorage.setItem("BotID", res.data.response);
                    navigate(`/create-bot/website-helper/${res.data.response}`)

                }
            })
    }

    const handleStramlineBot = () => {
        let CreateBotURL = `${BaseUrl}/Bot_ID`
        axios
            .post(CreateBotURL)
            .then((res) => {
                if (res.data.response) {
                    localStorage.setItem("BotID", res.data.response);
                    navigate(`/settings/streamline-assistant/${res.data.response}`)

                }
            })
    }

    const handleCallAssistBot = () => {

        let CreateBotURL = `${BaseUrl}/Bot_ID`

        axios
            .post(CreateBotURL)
            .then((res) => {
                if (res.data.response) {
                    localStorage.setItem("BotID", res.data.response);
                    navigate(`/create-bot/call-assistant/${res.data.response}`)

                }
            })
    }

    const handleTeachingAssistBot = () => {

        let CreateBotURL = `${BaseUrl}/Bot_ID`
        axios
            .post(CreateBotURL)
            .then((res) => {
                if (res.data.response) {
                    localStorage.setItem("BotID", res.data.response);
                    navigate(`/create-bot/teaching-assistant/${res.data.response}`)

                }
            })
    }

    const handleAgreementAssistBot = () => {

        let CreateBotURL = `${BaseUrl}/Bot_ID`
        axios
            .post(CreateBotURL)
            .then((res) => {
                if (res.data.response) {
                    let botTypeApi = `${BaseUrl}/bot_type`

                    let body = {
                        "bot_id" : res.data.response,
                        "bot_type" : 'agreement-assistant'
                    };

                    axios.post( botTypeApi, body )

                    .then(( response ) => {
                        if( response.data.status === 200 ){
                            localStorage.setItem("BotID", res.data.response);
                            navigate(`/create-bot/agreement-assistant/${res.data.response}`)
                        }
                        else{
                            openNotificationWithIconError('There is an issue posting bot type!')
                        }
                    })
                    .catch(( err ) => {
                        console.log(err.message);
                    })


                }
            })
            .catch(( err ) => {
                console.log('error!')
            })
    }

    const handleDataAssistBot = () => {

        let CreateBotURL = `${BaseUrl}/Bot_ID`
        axios
            .post(CreateBotURL)
            .then((res) => {
                if (res.data.response) {
                    localStorage.setItem("BotID", res.data.response);
                    navigate(`/create-bot/data-assistant/${res.data.response}`)

                }
            })
    }
    const HandleServer = (e) => {
        console.log(e, "serve")
        let CreateBotURL = `${BaseUrl}/Bot_ID`
        axios
            .post(CreateBotURL)
            .then((res) => {
                if (res.data.response) {
                    localStorage.setItem("BotID", res.data.response);

                    let ServerUrl = `${BaseUrl}/server?user_id=${UserID}&bot_id=${res.data.response}&server=${e}`
                    axios
                        .post(ServerUrl)
                        .then((res) => {
                            console.log(res)
                        })
                        .catch((err) => {
                            console.log(err)
                        })

                    setIsModalOpen(false);
                    navigate(`/create-bot/general/${res.data.response}`)
                }
            })
    }

    return (
        <div>
            <Headers />
            <div className='landingPageContent'>
                <Modal footer closable={false} maskClosable={false} title="Select Bot Deployment" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                    <Row>
                        <Col span={6}>
                            <div onClick={() => HandleServer('prod')}>
                                <img className='botTypeImage generalBotImg' src='ServerType/cloud-computing-svgrepo-com.svg' width={68} height={60} />
                                <p className='botTypeName'>Prod Server</p>
                            </div>
                        </Col>
                        <Col span={6} onClick={() => HandleServer('Uat')}>
                            <div>
                                <img className='botTypeImage generalBotImg' src='ServerType/server-svgrepo-com.svg' width={68} height={60} />
                                <p className='botTypeName'>UAT Server</p>
                            </div>
                        </Col>
                    </Row>
                </Modal>
                {/* <Row className="sourceStepRow">
                    <Col span={10}>
                        <Steps items={stepItem} current={0} labelPlacement="vertical" />
                    </Col>
                </Row> */}
                <Row>
                    <Col span={9} offset={2} className='landingPageCol'>
                        <h1 className='landingPageHeading'>Let's set up your chatbot</h1>
                        <p className='landingPageSubHeading'>And here how we are going to do it:</p>
                        <div className='landingPageSteps'>
                            <Steps
                                direction="vertical"
                                // size="small"
                                current={2}
                                status='finish'
                                items={stepItems}
                            />
                        </div>
                    </Col>
                    <Col span={12}>
                        <Row className='landinPageInsideRow'>
                            <Col offset={3} span={20} className='landingPageCol'>
                                <p className='landingPageHeading'>Choose type of bot</p>
                                <div className='botTypesDiv'>
                                <Row className='botTypeRow'>
                                    <Col span={7}>
                                        <div className='botTypeDiv' onClick={handleCreateBot}>
                                            <img className='botTypeImage generalBotImg' src='botType/general.svg' width={68} height={60} />
                                            <p className='botTypeName'>General Bot</p>
                                        </div>
                                    </Col>
                                    <Col span={9}>
                                        <div className='botTypeDiv comingSoonTypes'
                                            onClick={handleTeachingAssistBot}
                                        >
                                            <img className='botTypeImage teachingAssistImg' src='botType/teaching.svg' width={68} height={60} />
                                            <p className='botTypeName teachingAssistText'>Teaching Assistant</p>
                                        </div>
                                        {/* <p className='comingSoonText'>Coming Soon</p> */}
                                    </Col>
                                    <Col span={8}>
                                        <div className='botTypeDiv comingSoonTypes'
                                            onClick={handleCallAssistBot}
                                        >
                                            <img className='botTypeImage callCenterImg' src='botType/callcenter.svg' width={68} height={60} />
                                            <p className='botTypeName callCenterText'>Call Assistant</p>
                                        </div>
                                        {/* <p className='comingSoonText'>Coming Soon</p> */}
                                    </Col>
                                </Row>
                                <Row className='botTypeRow2'>
                                    <Col span={8}>
                                        <div className='botTypeDiv comingSoonTypes'
                                            onClick={handleWebsiteCreateBot}
                                        >
                                            <img className='botTypeImage websiteHelperImg' src='botType/webhelper.svg' width={68} height={60} />
                                            <p className='botTypeName websiteHelperText'>Website Helper</p>
                                        </div>
                                        {/* <p className='comingSoonText'>Coming Soon</p> */}
                                    </Col>
                                    <Col span={7}>
                                        <div className='botTypeDiv comingSoonTypes'
                                            onClick={handleDataAssistBot}
                                        >
                                            <img className='botTypeImage preventionBotImg' src='botType/prevention.svg' width={68} height={60} />
                                            <p className='botTypeName preventionBotText'>Data Assistant</p>
                                        </div>
                                        {/* <p className='comingSoonText'>Coming Soon</p> */}
                                    </Col>
                                    <Col span={9}>
                                        <div className='botTypeDiv comingSoonTypes'
                                            onClick={handleStramlineBot}
                                        >
                                            {/* <img className='botTypeImage streamlineImg' src='botType/webhelper.svg' width={68} height={60} /> */}
                                            <InsertPageBreakIcon className='botTypeImage streamlineImg' />
                                            <p className='botTypeName streamlineText'>Streamline Assistant</p>
                                        </div>
                                        {/* <p className='comingSoonText'>Coming Soon</p> */}
                                    </Col>
                                </Row>
                                {/* <Row className='botTypeRow3'>
                                    <Col offset={7}>
                                    <div className='botTypeDiv comingSoonTypes'
                                            onClick={handleAgreementAssistBot}
                                        >
                                           <FindInPageIcon className='botTypeImage streamlineImg' />
                                            <p className='botTypeName agreementText'>Agreement Assistant</p>
                                        </div>
                                    </Col>
                                </Row> */}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </div>
            <Footer />
        </div>
    )
}

export default Landing
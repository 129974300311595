import React, { useEffect, useState } from "react";
import { message, Upload, Button, Card, notification, Progress } from "antd";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { BaseUrl, MicroUrl1, MicroUrl2, REACT_APP_ACESS_KEY_ID, REACT_APP_SECRET_ACESS_KEY } from "../../../../Constants/Constant";
import { useStateValue } from "../../../Reducer/reducer";
import { actionTypes } from "../../../Reducer/stateProvider";
import './File.css'
import AWS from 'aws-sdk';
import { useParams, useNavigate } from "react-router-dom";

const File = () => {
  const [{ sourceFile, sourceData, sourceDataFile }, dispatch] =
    useStateValue();

  const [api, contextHolder] = notification.useNotification();


  const storedFile = localStorage.getItem("uploadedFile");

  const [selectedFile, setSelectedFile] = useState([]);
  const [fileObject, setFileObject] = useState([]);
  const [Percent, setPercent] = useState('0')
  const [uploadedFiles, setUploadedFiles] = useState(
    sourceDataFile && sourceDataFile.length > 0 ? sourceDataFile : []
  );
  const [loadings, setLoadings] = useState([]);
  const [loading, setLoading] = useState('');
  const [vectorError, setVectorError] = useState("");
  const [uploadError, setUploadError] = useState("");
  const [previewImage, setPreviewImage] = useState('');
  const [previewFilename, setPreviewFilename] = useState([])
  const [file, setfile] = useState([]);
  const [fileFormats, setFileFormats] = useState('');
  const [acceptFile, setAcceptFile] = useState('');
  const [arrayFiles, setarrayFiles] = useState([])
  const [DeleteLoading, setDeleteLoading] = useState(false)

  const UserID = localStorage.getItem("UserID");
  let url = window.location.href;
  let BotID = url.split("/").slice(-1);
  const navigate = useNavigate();
  let { type } = useParams();
  
  console.log(type, 'botTypePreviewFile')
  const openNotificationWithIconSuccess = (type) => {
    console.log('getsSuccess')
    api['success']({
      message: "Success!!!",
      description:
        type,
    });
  };

  const openNotificationWithIconError = (type) => {
    api['error']({
      message: "Oops!!!",
      description:
        type,
    });
  };

  const openNotificationWithIconWarning = (type) => {
    api['warning']({
      message: "Warning!!!",
      description:
        type,
    });
  };

  useEffect(() => {
    if (type === 'teaching-assistant' || type === 'agreement-assistant') {
      setFileFormats('.pdf');
      setAcceptFile(".pdf");
    } else if (type === 'data-assistant') {
      setFileFormats('.csv')
      setAcceptFile(".csv")
    } else {
      setFileFormats('.pdf, .docx, .txt, .mp3, .mp4, .weba, .wav, .ppt, .pptx');
      setAcceptFile("*");
    }
  }, [])

  const handleFile = (file) => {
    console.log(file, "uploadFile");

    console.log(file.file.originFileObj, "selectedFile");

    const files = file.fileList.map((item) => item.originFileObj);

    setSelectedFile(files);

    setVectorError("");
    setUploadError("");
  };


  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000000);
  };


  console.log(selectedFile, "selectedFile");

  useEffect(() => {
    if (storedFile) {
      setFileObject(JSON.parse(storedFile));
    } else {
      setFileObject([]);
    }
  }, [storedFile]);

  console.log(fileObject, "fileObjectUpload");
  useEffect(() => {
    const socket = new WebSocket(`wss://api-uat.precium.ai/ws/${BotID[0]}`);

    // Connection opened
    socket.addEventListener('open', (event) => {
      console.log('WebSocket connected', event);

      // You can send messages or perform other actions here
    });

    // Listen for messages
    socket.addEventListener('message', (event) => {
      const socketData = JSON.parse(event.data)
      console.log('Message from server:', JSON.parse(event.data), typeof event.data);
      setPercent(socketData.percentage)
      if (socketData.percentage === 100) {
        setLoading(false)
        openNotificationWithIconSuccess("File Uploaded Successfully!");
        dispatch({
          type: actionTypes.SET_SOURCEFILE,
          sourceFile: true,
        });
      }
    });

    // Connection closed
    socket.addEventListener('close', (event) => {
      console.log('WebSocket closed', event.code, event.reason);
      // Handle socket closure here
    });

    // Clean up the socket connection when the component unmounts
    return () => {
      socket.close();
    };
  }, [])
  const handleUpload = async () => {
    setLoading(true)
    enterLoading(0);
    const URL = `${MicroUrl1}/upload_file?user_id=${UserID}&bot_id=${BotID}`;
    // const URL = `${BaseUrl}/upload_file?user_id=${UserID}&bot_id=${BotID}`;

    if (selectedFile) {
      const formData = new FormData();

      selectedFile.forEach((file) => {
        formData.append("files", file);
      });

      axios
        .post(URL, formData, {
          headers: {
            "Content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res.data.message, "pdfFilePath");
          if (res.data.status === 200) {

            let vectorUrl = `${MicroUrl2}/vector_creation/files`;
            // let vectorUrl = `${BaseUrl}/vector_creation/files`;

            let filePath = res.data.file_path;

            let body = {
              user_id: UserID,
              bot_id: BotID[0],
              file_path: filePath,
            };

            axios.post(vectorUrl, body)
              .then((res) => {
                console.log(res.data.status, "vectorCreationPdfRes");
                if (res.data.status == 200) {
                  openNotificationWithIconSuccess("File Uploaded Successfully!");

                  // message.success("File Uploaded Successfully!");
                  // console.log('first');
                  dispatch({
                    type: actionTypes.SET_SOURCEFILE,
                    sourceFile: true,
                  });
                  stopLoading(0);
                }
                else if (res.data.status == 400) {
                  openNotificationWithIconError(res.data.response);
                  // message.error(res.data.response);

                  stopLoading(0);
                }
                setLoading(false)
              })
              .catch((err) => {

                setLoading(false)
                openNotificationWithIconError('Error while creating vector! Please re-upload the file.')
                // setVectorError('Error while creating vector! Please re-upload the file.');
                // message.success('Error while creating error!');
                console.log(err, 'Vector creation error!.');
              })

          }
        })
        .catch((err) => {
          stopLoading(0);
          setLoading(false)
          // message.error(err.message);
          openNotificationWithIconError('Upload Failed! Please re-upload');
          // setUploadError('Upload Failed! Please re-upload')
          console.log(err, "Upload Error");
          setUploadError('Upload Failed! Please re-upload')
        });
    } else {
      dispatch({
        type: actionTypes.SET_SOURCEFILE,
        sourceFile: false,
      });
      // message.warning("Please select a file!");
      openNotificationWithIconWarning("Please select a file!");
    }
  };

  const isPdf = file => {
    return file.type === 'application/pdf';
  };

  const isCsv = file => {
    return file.type === 'text/csv' || file.name.endsWith('.csv');
  };

  const handlePreview = (files) => {

    console.log(files.file, "uploadedfile")

    if (type === 'teaching-assistant' || type === 'agreement-assistant') {
      const csvFiles = files.fileList.filter(file => isPdf(file.originFileObj));

      // if (allPdf) {
      if (csvFiles.length === files.fileList.length) {
        const csvFiles = files.fileList.map(item => item.originFileObj);
        setfile(csvFiles);
        setPreviewImage(files.file.url || files.file.thumbUrl);
      } else {
        // openNotificationWithIconError('You can only upload PDF files & other formats will not be uploaded!');
        setVectorError('You can only upload PDF files & other formats will not be uploaded!');
        // Set the state variable to include only valid CSV files
        const validCsvFiles = csvFiles.map(item => item.originFileObj);
        setfile(validCsvFiles);

        // Optionally, you can reset the fileList to remove invalid files from the preview
        const newFiles = [...files.fileList];
        newFiles.splice(0, newFiles.length, ...csvFiles);
        // Update the fileList to include only valid files
        files.fileList = newFiles;
      }
    } else if (type === 'data-assistant') {
      // debugger;
      // const allCsv = files.fileList.every(file => isCsv(file.originFileObj));
      const csvFiles = files.fileList.filter(file => isCsv(file.originFileObj));

      if (csvFiles.length === files.fileList.length) {
        const csvFiles = files.fileList.map(item => item.originFileObj);
        setfile(csvFiles);
        setPreviewImage(files.file.url || files.file.thumbUrl);
      } else {
        setVectorError('You can only upload CSV files & other formats will not be uploaded!')
        const invalidFiles = files.fileList.filter(file => !isCsv(file.originFileObj));
        const validCsvFiles = csvFiles.map(item => item.originFileObj);

        // Set the state variable to include only valid CSV files
        setfile(validCsvFiles);

        // Optionally, you can reset the fileList to remove invalid files from the preview
        const newFiles = [...files.fileList];
        newFiles.splice(0, newFiles.length, ...csvFiles);
        // Update the fileList to include only valid files
        files.fileList = newFiles;
      }
    } else {
      setPreviewImage(files.file.url || files.file.thumbUrl);
      const files1 = files.fileList.map((item) => item.originFileObj);
      setfile(files1)
    }


  };



  AWS.config.update({
    region: 'us-east-1',
    credentials: new AWS.Credentials({
      accessKeyId: REACT_APP_ACESS_KEY_ID,
      secretAccessKey: REACT_APP_SECRET_ACESS_KEY,
    }),
  });

  // let { type } = useParams();
  const s3 = new AWS.S3();

  useEffect(() => {
    // Use map to extract file names from the 'file' array
    const fileNames = file.map((i) => `https://ded2w7tw5rnvn.cloudfront.net/dev/${UserID}/${BotID}/${i.name}`)

    // Set the array of file names using setarrayFiles
    setarrayFiles(fileNames);
  }, [file]);



  const uploadFileToS3 = () => {
    if (file.length === 0) {
      setVectorError('Please upload at least one file or upload suggested format files!');
      return;
    }

    setLoading(true);
    setPercent('0')

    const uploadPromises = file.map((file) => {

      const params = {
        Bucket: 'preciumweb-dev',
        Key: `dev/${UserID}/${BotID}/${file.name}`,
        Body: file,
      };

      return new Promise((resolve, reject) => {
        s3.upload(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
            setPercent("50")

            console.log(arrayFiles, "chanarrayyy")

          }
        });
      });
    });

    Promise.all(uploadPromises)
      .then(() => {
        // setLoading(false);
        let body = {
          // url: `dev/${UserID}/${BotID}/${file.name}`,
          urls : arrayFiles,
        };
  
        axios
          .post(`${BaseUrl}/upload_S3_file`, body)
          .then((res) => {
            // debugger;
            console.log(res, "s3 res");
            setPercent("100")
            openNotificationWithIconSuccess("File Uploaded and vector creation started");
            dispatch({
              type: actionTypes.SET_SOURCEFILE,
              sourceFile: true,
            });

            setTimeout(() => {
              dispatch({
                type: actionTypes.SET_MANAGEACTIVEKEY,
                manageActiveKey : "2"
              })
              // navigate(`/settings/${type}/${BotID[0]}`)
            }, 2000)
          })
          .catch((err) => {
            console.log(err, "s3err");
            setVectorError('Upload Failed');
            setLoading(false);
          });
        setfile([]); // Clear the uploaded files
      })
      .catch((err) => {
        console.log(err, "upload err");
        // Handle error
      });

   
  };



  const fileDelete = (filePath, index) => {

    console.log(filePath, 'fileIndex')
    setDeleteLoading(true)
    let deleteUrl = `${BaseUrl}/delete_files`

    axios.post(deleteUrl, { "file_path": filePath })
      .then((res) => {

        let status = res.data.status;

        const defaultUploadedFiles = [...uploadedFiles];

        if (status == 200) {
          setDeleteLoading(false)

          defaultUploadedFiles.splice(index, 1);

          setUploadedFiles(defaultUploadedFiles);

        } else {
          // message.error(res.data.response);
          openNotificationWithIconError(res.data.response);
          setUploadedFiles(defaultUploadedFiles)
        }
      })
      .catch((err) => {
        openNotificationWithIconError(err.message);
      })

    // const defaultUploadedFiles = [...uploadedFiles];

    // defaultUploadedFiles.splice(index, 1);

    // setUploadedFiles(defaultUploadedFiles);

  }

  const stopLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = false;
      return newLoadings;
    });
  };

  console.log(sourceData, "sourceData");

  return (
    <>
      {contextHolder}
      <div>
        <div style={{ display: "flex" }}>
          <h4 style={{ margin: "0px" }}>File Upload</h4>
          <p
            style={{ margin: "2px 0 0 5px", fontSize: "12px" }}
          >{`(Supported formats - ${fileFormats})`}</p>
        </div>
        <p className="errorMessage">Maximum File Size 100MB!</p>
        <div
          className="fileUploadDiv"
          style={{ marginTop: "8px", marginLeft: "0px", maxWidth: "375px" }}
        >
          <Upload
            // onChange={handleFile}
            onChange={handlePreview}
            accept={acceptFile}
            // fileList={ sourceData != {} ?  [
            //   {
            //     name: sourceData.file_name,
            //     url: sourceData.file_name,
            //   },
            // ] : ""}
            // {...props}
            // fileList={selectedFile}
            customRequest={({ onSuccess }) => onSuccess("ok")}
            className="Upload"
          >
            <Button onClick={() => setVectorError('')} icon={<UploadOutlined />}> upload</Button>
          </Upload>
          {/* <p>{`(Supported Formats : .pdf, .docx, .txt, .mp3, .mp4, .weba, .wav)`}</p> */}
        </div>
      </div>
      {
        file.length != 0 ?
          <></>
          :
          <h1 className="errorMessageUpload">{vectorError}</h1>
      }
      <div>
        {uploadedFiles && uploadedFiles.length > 0 ? (
          uploadedFiles.map((item, index) => {
            console.log(item.file_path, 'fileListss')
            let filePath = item.file_path;
            return (
              <div className="uploadedSourceFileDiv">
                <Card className="uploadedSourceFileDivCard">
                  <div className="uploadedSourceFileDivCardDiv">
                    <p>{index + 1 + ". " + item.file_name}</p>
                    {
                      DeleteLoading === true ? <img style={{height:25,width:25}}  src="/loader/tail-spin.svg" className="loaderImage" />
                       :

                        <DeleteOutlined
                          onClick={() => fileDelete(filePath, index)}
                          className="deleteIcon"


                        />
                    }
                  </div>
                </Card>
              </div>
            )
          })
        ) : (
          <></>
        )}
      </div>

      {/* <div>
          <h1 className="errorMessageUpload">{uploadError}</h1>
        </div> */}

      <div>

        <Button
          className={loading == false ? "settingSave" : "settingSaveloading"}
          // loading={loadings[0]}
          onClick={uploadFileToS3}
        >
          {
            loading == true ?
              <Progress strokeColor={'#fff'} className="progress" percent={Percent} type="circle" />
              :
              <>

                Save
              </>


          }
        </Button>
      </div>
    </>
  );
};

export default File;

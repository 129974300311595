// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sourceInput{
    height: 44px;
}
.settingSave{
    margin: 6% 0 5% 30%;
    width: 300px;
    height: 38px;
    background: #b670d0;
    color: #fff;
    border-color: #b670d0;
    font-size: 17px;
}
.settingSave.ant-btn-default:hover {
    color: #b670d0 !important;
    background: #f3e8f7;
    border-color: #b670d7 !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/CreateChatBot/Source/Text/Text.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB,WAAW;IACX,qBAAqB;IACrB,eAAe;AACnB;AACA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,gCAAgC;AACpC","sourcesContent":[".sourceInput{\n    height: 44px;\n}\n.settingSave{\n    margin: 6% 0 5% 30%;\n    width: 300px;\n    height: 38px;\n    background: #b670d0;\n    color: #fff;\n    border-color: #b670d0;\n    font-size: 17px;\n}\n.settingSave.ant-btn-default:hover {\n    color: #b670d0 !important;\n    background: #f3e8f7;\n    border-color: #b670d7 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { Col, Row, Input, Button, message,Form, notification} from 'antd'
import './ForgotPassword.css'
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import axios from 'axios'
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router';
import { useState } from 'react'
import { BaseUrl } from '../../Constants/Constant'

const ForgotPassword = () => {

  const [Email, setEmail] = useState("")
  const [OTPCheck, setOTPCheck] = useState("")
  const [OtpVerify, setOtpVerify] = useState(false)
  const [otp, setOtp] = useState('');
  const [passwordPage, setpasswordPage] = useState(false)
  // const [messageApi, contextHolder] = message.useMessage();
  const [api, contextHolder] = notification.useNotification();
  const [Password, setPassword] = useState("")
  const [pwdStatus, setPwdStatus] = useState('');
  const [CpwdStatus, setCPwdStatus] = useState('');
  const [ConfirmPass, setConfirmPass] = useState("")
  const navigate = useNavigate()
  const [Disabled, setDisabled] = useState(false)

  // const success = (e) => {
  //   messageApi.open({
  //     type: 'success',
  //     content: e,
  //   });
  // };
  // const error = (e) => {
  //   messageApi.open({
  //     type: 'error',
  //     content: e,
  //   });
  // };


  const openNotificationWithIconSuccess = (type) => {
    console.log('getsSuccess')
    api['success']({
      message: "Success!!!",
      description:
        type,
    });
  };

const openNotificationWithIconError = (type) => {
    api['error']({
      message: "Oops!!!",
      description:
        type,
    });
  };

  

  const handleSubmit = () => {
    enterLoading(0)
    let forgetPassUrl = `${BaseUrl}/forgot_password/request`
    let body = {
      email: Email
    }
    axios
      .post(forgetPassUrl, body)
      .then((res) => {
        if (res.data.status === 200) {
          // success(res.data.response);
          openNotificationWithIconSuccess(res.data.response);
          setOTPCheck(JSON.stringify(res.data.message))
          setOtpVerify(true)


        } else if (res.data.status === 400) {
          // error(res.data.response);
          openNotificationWithIconError(res.data.response);
          setOtpVerify(false)
        }
        // console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })

  }
  console.log(OTPCheck, "checkedOtp", otp, otp == OTPCheck)

  const handleClick = () => {
    
    if (otp == OTPCheck) {
      setpasswordPage(true)
      openNotificationWithIconSuccess("OTP verified Sucessfully");
      // success("OTP verified Sucessfully");
    } else {
      openNotificationWithIconError("OTP doesnot match");
      // error("OTP doesnot match");
    }
  }

  const [loadings, setLoadings] = useState([]);
  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 6000);
  };

  const handleNewPass = () => {
    
    let newPassUrl =`${BaseUrl}/forgot_password/update_password`
    let  body ={
      email: Email,
      password: ConfirmPass
    }
axios
.post(newPassUrl,body)
.then((res) => {
  // console.log(res,"passwordRes")
  if (res.data.status === 200) {
    navigate("/Login");
    openNotificationWithIconSuccess("New password set sucessfully");
    // success("New password set sucessfully");
  }else{
    // error(res.data.response);
    openNotificationWithIconError(res.data.response);
  }
})
  }

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    // Password validation regex pattern
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
    const isValidPassword = passwordRegex.test(value);

    // Update password status based on validation
    setPwdStatus(isValidPassword ? 'success' : 'error');
    console.log(isValidPassword,"isValidPassword")
  };
  const handlePasswordChange1 = (e) => {
    const value = e.target.value;
    setConfirmPass(value);

    // Password validation regex pattern
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
    const isValidPassword = passwordRegex.test(value);

    // Update password status based on validation
    setCPwdStatus(isValidPassword ? 'success' : 'error');
    console.log(isValidPassword,"isValidPassword")
  };
  return (
    <div>
      {contextHolder}
      <Row>
        <Col span={12} style={{ margin: 0 }}>
        <img src='/Home/Precium-logo.png' className='headerLogoOnly' />
          <img src='/login/sidePane.svg' className='loginImage' alt='SidePaneImage' />
        </Col>
        <Col offset={2} span={8}>
          <div className='logindiv1'>
            <h1 className='LoginH1'>
              Forgot Password
            </h1>
          </div>

          {OtpVerify === false ? <><Input
            prefix={<MailOutlined className='mailIcon' />}
            className='loginInput'
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email ID" />

            <div className='SubmitButton'>

              <Button
                //   onClick={handleLogin}
                className='LoginButton'
                disabled={!Email ? true : false}
                loading={loadings[0]}
                onClick={handleSubmit}>
                Submit
              </Button>




            </div>
          </>

            :

            <div className='otpInput'>
              {
                passwordPage == false ?
                  <>
                    <h3 style={{ marginBottom: 10, marginLeft: 11 }}>
                      Enter OTP
                    </h3>
                    <div 
                      className='otpBoxInput'>
                    <OtpInput 
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderInput={(props) => <input {...props} />}
                    />
                    </div>
                    <div className='SubmitButton'>

                      <Button
                        //   onClick={handleLogin}
                        className='LoginButton'
                        onClick={otp.length == 6 ? handleClick() : handleClick}
                      >
                        Submit
                      </Button>
                    </div>





                  </> :
                  <>
                  <Form.Item className='PasswordForm'  hasFeedback validateStatus={pwdStatus}>
                    <Input.Password
                      prefix={<LockOutlined className='mailIcon' />}
                      status={!ConfirmPass ? "" : Password !== ConfirmPass ? "error" : "" }
                      onChange={handlePasswordChange}
                      className='loginInput' placeholder="Password" type='password' />
                      </Form.Item>
                      <Form.Item className='PasswordForm'  hasFeedback validateStatus={CpwdStatus}>
                    <Input.Password
                      prefix={<LockOutlined className='mailIcon' />}
                      status={!ConfirmPass ? "" : Password !== ConfirmPass ? "error" : "" }
              onChange={handlePasswordChange1}
                      className='loginInput' placeholder="Confirm Password" type='password' />
                      </Form.Item>




                    <div className='SubmitButton'>

                      <Button
                      disabled={  Password != ConfirmPass? true : false}
                      // loading="true"
                        className='LoginButton' onClick={handleNewPass} >
                        Submit
                      </Button>
                    </div>
                  </>

              }
            </div>

          }



        </Col>
      </Row>
    </div>
  )
}

export default ForgotPassword
import { Button, Col, Form, Row ,Input, Select, notification} from 'antd'
import React,{useState} from 'react'
// import './Database.css'
import axios from 'axios'
import { useNavigate, useParams } from "react-router-dom";
import { useStateValue } from "../../../Reducer/reducer";
import { actionTypes } from "../../../Reducer/stateProvider";
import { BaseUrl } from '../../../../Constants/Constant'
import './Database.css'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'; 

const Database = () => {
  const [{ sourceWeb, sourceDataWebsite }, dispatch] = useStateValue();

  const [HostName, setHostName] = useState("")
  const [Port, setPort] = useState("")
  const [UserName, setUserName] = useState("")
  const [DbPassword, setDbPassword] = useState("")
  const [DbType, setDbType] = useState("")
  const navigate = useNavigate();

  let { type } = useParams();


  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIconSuccess = (type) => {
    console.log('getsSuccess')
    api['success']({
      message: "Success!!!",
      description:
        type,
    });
  };

const openNotificationWithIconError = (type) => {
    api['error']({
      message: "Oops!!!",
      description:
        type,
    });
  };

  const openNotificationWithIconWarning = (type) => {
    api['warning']({
      message: "Warning!!!",
      description:
        type,
    });
  };

    const handleSubmit =() => {
      let DBURL = `${BaseUrl}/insert_data?host=${HostName}&port=${Port}&username=${UserName}&password=${DbPassword}&db_type=${DbType}`


      axios
      .post(DBURL)
      .then((res) => {
        console.log(res,"DB")
        if(res.data.status == 200){
          dispatch({
            type: actionTypes.SET_WEBSOURCE,
            webSource: res.data.message,
          });
          openNotificationWithIconSuccess('Connecting to Database!')
          navigate('/GetBackSoon')
        }
        else{
          dispatch({
            type: actionTypes.SET_WEBSOURCE,
            webSource: res.data.message,
          });
          openNotificationWithIconError('Cannot connect to the database!')
          navigate('/GetBackSoon')
        }
      })
      .catch((err) => {
        console.log(err);
        openNotificationWithIconError(err.message);
      })
    }
  return (
    <div className='Database-main'>
      {contextHolder}
        <h3>
            Database
        </h3>
        <Form>

        <Row>
            <Col span={12}>
            
        <Row style={{marginTop:30,marginBottom:30}}>
          
            <Form.Item label="HostName" required tooltip="This is a required field">
            <Input placeholder='HostName' className='Database-Input' 
            onChange={(e) => setHostName(e.target.value)}
            />
            </Form.Item>
            </Row>
            <Row style={{marginTop:30,marginBottom:30}}>
            <Form.Item label="Port" required tooltip="This is a required field">
            <Input type='number' placeholder='Port' className='Database-Input'
            onChange={(e) => setPort(e.target.value)} />
            </Form.Item>
        </Row>
        <Row style={{marginTop:30,marginBottom:30}}>
     
            <Form.Item label="User Name" required tooltip="This is a required field">
            <Input placeholder='User Name'  className='Database-Input'
            onChange={(e) => setUserName(e.target.value)} />
            </Form.Item>
         </Row>
        <Row style={{marginTop:30,marginBottom:30}}>
          
            <Form.Item label="Password" required tooltip="This is a required field">
            <Input placeholder='Password' className='Database-Input'
            onChange={(e) => setDbPassword(e.target.value)} />
            </Form.Item>
           
        </Row>
        <Row style={{marginTop:30,marginBottom:30}}>
          <h4>
          Drive
          </h4>
            <Select className='Database-Input'
            onChange={(e) => setDbType(e)}
              options={[
                {
                  value: 'Sql',
                  label: 'Sql',
                },
                {
                  value: 'Postgress',
                  label: 'Postgress',
                },
                {
                  value: 'Oracle',
                  label: 'Oracle',
                }
              ]} >
       
                </Select>
         
           
        </Row>
        </Col>
        <Col span={12}>
        </Col>
        </Row>
        </Form>
        <Row className='databaseSaveRow'>
          <Col span={4}>
          <Button
            // disabled={!Text ? true:false}
            onClick={() => navigate('/create-type')}
            // loading={loadings[0]}
            className="createSettingSave"
          >
           <LeftOutlined /> Back
          </Button>
          </Col>
          <Col span={4}>
            <Button className='createSettingSave' onClick={handleSubmit}>
              Next <RightOutlined />
            </Button>
          </Col>
        </Row>
    </div>
  )
}

export default Database
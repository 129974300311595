import React, { useEffect,useState } from 'react'
import { Collapse } from 'antd';
import axios from 'axios';
import './History.css'
import HTMLReactParser from "html-react-parser";
import { BaseUrl } from '../../Constants/Constant';

const History = () => {
    const [Items, setItems] = useState([])
    const [thumbsDown, setthumbsDown] = useState('')
    const [thumbsupImg, setthumbsupImg] = useState('')
    let url = window.location.href; 
    let BotID = url.split('/').slice(-1)
    const UserID = localStorage.getItem("UserID");

useEffect(() => {
    let URL = `${BaseUrl}/last_five_queries?user_id=${UserID}&bot_id=${BotID}`
    axios
    .post(URL)
    .then((res) => {
        console.log(res.data,"res")
        const thumbsupImg = <img src="/messageicon/ThumbsUp.svg" width={20} className="feedbackImage1" />;
        const thumbsdownImg = <img src="/messageicon/ThumbsDown.svg" width={20} className="feedbackImage1" />;
        const Array = res.data
        const modifiedDateArray = Array.map(item => {
            const dateObject = new Date(item.extra);
            const day = dateObject.getDate();
            const month = dateObject.getMonth() + 1; 
            const year = dateObject.getFullYear();
            const hours = dateObject.getHours();
            const minutes = dateObject.getMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            const thumbsup = item.positive_reaction_count
            const thumbsdown = item.negative_reaction_count
           
            console.log(thumbsup,"reaction",thumbsdown)

            
            const modifiedExtra = (
                <div>
                     {thumbsup === 1 ? thumbsupImg : null}
                    {thumbsdown === 1 ? thumbsdownImg : null}
                    {`${month}-${day}-${year} ${hours % 12}.${minutes.toString().padStart(2, '0')} ${ampm}`}

                   
                </div>
            );
            
            return { ...item, extra: modifiedExtra };
          });
        setItems(modifiedDateArray)
    })
    .catch((err) => {
        console.log(err)
    })

}, [])

  return (
    <div style={{marginBottom:10}}>
        <Collapse items={Items} bordered={false} defaultActiveKey={['1']} />
        </div>
  )
}

export default History
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sourceDiv{
    margin-top: 50px;
    min-height: 72vh;
}
.sourceDiv .ant-tabs-nav{
    margin-bottom: 22px;
}
.sourceDiv .ant-tabs-top >.ant-tabs-nav::before,.sourceDiv .ant-tabs-bottom >.ant-tabs-nav::before,.sourceDiv .ant-tabs-top >div>.ant-tabs-nav::before,.sourceDiv .ant-tabs-bottom >div>.ant-tabs-nav::before{
    border-bottom: none;
}
.sourceDiv .ant-tabs .ant-tabs-ink-bar{
    background: none;
}
.sourceDiv .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    padding: 3px 24px;
    border: 1px solid #b670d0;
    border-radius: 10px;
    background: #b670d0;
    color: #fff !important;
}
.createBotSource .ant-tabs-tab+.ant-tabs-tab{
    margin: 0 0 0 60px;
}
.createBotSource .ant-tabs-tab+.ant-tabs-tab-active{
    margin: 0 0 0 60px;
}
.createBotSource .ant-tabs-nav-list{
    margin-left: 78px;
}
.createBotSource .ant-tabs-content-holder{
    width: 100%;
}
.sourceStepRow{
    margin-bottom: 4%;
}
.sourceStepRow .ant-steps-item-content{
    margin-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/NewCreateBot/NewSource/Source.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,mBAAmB;IACnB,mBAAmB;IACnB,sBAAsB;AAC1B;AACA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,WAAW;AACf;AACA;IACI,iBAAiB;AACrB;AACA;IACI,cAAc;AAClB","sourcesContent":[".sourceDiv{\n    margin-top: 50px;\n    min-height: 72vh;\n}\n.sourceDiv .ant-tabs-nav{\n    margin-bottom: 22px;\n}\n.sourceDiv .ant-tabs-top >.ant-tabs-nav::before,.sourceDiv .ant-tabs-bottom >.ant-tabs-nav::before,.sourceDiv .ant-tabs-top >div>.ant-tabs-nav::before,.sourceDiv .ant-tabs-bottom >div>.ant-tabs-nav::before{\n    border-bottom: none;\n}\n.sourceDiv .ant-tabs .ant-tabs-ink-bar{\n    background: none;\n}\n.sourceDiv .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{\n    padding: 3px 24px;\n    border: 1px solid #b670d0;\n    border-radius: 10px;\n    background: #b670d0;\n    color: #fff !important;\n}\n.createBotSource .ant-tabs-tab+.ant-tabs-tab{\n    margin: 0 0 0 60px;\n}\n.createBotSource .ant-tabs-tab+.ant-tabs-tab-active{\n    margin: 0 0 0 60px;\n}\n.createBotSource .ant-tabs-nav-list{\n    margin-left: 78px;\n}\n.createBotSource .ant-tabs-content-holder{\n    width: 100%;\n}\n.sourceStepRow{\n    margin-bottom: 4%;\n}\n.sourceStepRow .ant-steps-item-content{\n    margin-left: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

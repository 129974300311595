// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Database-main{
    margin: 0;
}
.Database-Input{
    height: 44px;
    width: 100%;
}
.Database-main .ant-row {
    display: block;
    flex-flow: row wrap;
    min-width: 0;
}
.Database-main .ant-col{
margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Components/CreateChatBot/Source/Database/Database.css"],"names":[],"mappings":"AAAA;IACI,SAAS;AACb;AACA;IACI,YAAY;IACZ,WAAW;AACf;AACA;IACI,cAAc;IACd,mBAAmB;IACnB,YAAY;AAChB;AACA;AACA,SAAS;AACT","sourcesContent":[".Database-main{\n    margin: 0;\n}\n.Database-Input{\n    height: 44px;\n    width: 100%;\n}\n.Database-main .ant-row {\n    display: block;\n    flex-flow: row wrap;\n    min-width: 0;\n}\n.Database-main .ant-col{\nmargin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

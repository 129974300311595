import React from "react";
import { useState, useEffect } from "react";
import { UploadOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Input,
  Slider,
  ColorPicker,
  Button,
  Switch,
  message,
  Upload,
  Select,
  notification
} from "antd";
import "./Settings.css";
import BotPreview from "../../../Preview/BotPreview";
import axios from "axios";
import { BaseUrl } from "../../../Constants/Constant";
import { useStateValue } from "../../Reducer/reducer";
import { useParams } from "react-router-dom";
import BotPreviewTest from "../../../Preview/BotPreviewTest";

const Settings = () => {
  const [ShowAssignTo, setShowAssignTo] = useState(false)
  const [{ sourceFile, sourceWeb, sourceText, botDetails, botSettings, role }] = useStateValue();
  console.log(botSettings, "botttttt")

  
  const Role = localStorage.getItem("Role")
  useEffect(() => {
    if (Role === "2") {
      setShowAssignTo(true)
    }
  }, [])

  console.log(Role, "Role")


  const { TextArea } = Input;
  const [PublicRes, setPublicRes] = useState(false);
  const [ reference, setReference ] = useState(botSettings?.referance_page || false);
  // const [ botSettings, setBotSettings ] = useState({});
  const [BotName, setBotName] = useState(botDetails?.chatbot_name || botSettings?.chatbot_name || "");
  const [BubbleClr, setBubbleClr] = useState(
    botDetails?.chat_bubble_colour || botSettings?.chat_bubble_colour || "#6B24D6"
  );
  const [MessageClr, setMessageClr] = useState(
    botDetails?.chat_conversation_colour || botSettings?.chat_conversation_colour || "#fff"
  );
  // const [ProfileImg, setProfileImg] = useState( botDetails?.profile_picture || "")
  // const [botProfile, setbotProfile] = useState( botDetails?.chat_icon || "")
  const [ProfileImg, setProfileImg] = useState(
    botDetails?.profile_picture || botSettings?.profile_picture || ""
  );
  const [botProfile, setbotProfile] = useState(botDetails?.chat_icon || botSettings?.chat_icon || "");
  const [Alignment, setAlignment] = useState(
    botDetails?.align_chat_bubble_button || botSettings?.align_chat_bubble_button || "Right"
  );
  const [WelcomeMsg, setWelcomeMsg] = useState(
    botDetails?.welcome_message || botSettings?.welcome_message || "Hi! How can I help you today..."
  );
  const [errMsg, setErrMsg] = useState(false);
  const [PromptMsg, setPromptMsg] = useState("");
  const [welcomeImg, setWelcomeImg] = useState(botDetails?.welcome_image || botSettings?.welcome_image || "");

  const [userList, setuserList] = useState([])

  // const [messageApi, contextHolder] = message.useMessage();
  const [api, contextHolder] = notification.useNotification();


  let { type } = useParams();


  console.log(type,'paramsType')

  const openNotificationWithIconSuccess = (type) => {
    console.log('getsSuccess')
    api['success']({
      message: "Success!!!",
      description:
        type,
    });
  };

  const openNotificationWithIconError = (type) => {
    api['error']({
      message: "Oops!!!",
      description:
        type,
    });
  };


  const openNotificationWithIconWarning = (type) => {
    api['warning']({
      message: "Warning!!!",
      description:
        type,
    });
  };

  console.log(reference, 'referencePage')

  // const BotID = localStorage.getItem("BotID");
  let url = window.location.href;
  let BotID = url.split("/").slice(-1);
  const UserID = localStorage.getItem("UserID");
  console.log(type, 'botIdSetting');






  const props = {
    onChange(info) {
      // const URL = `${BaseUrl}/upload_file?user_id=${UserID}&bot_id=${BotID}`;
      // const formData = new FormData();
      // formData.append("file", info.file.originFileObj);
      // if (info.file) {
      //   axios
      //     .post(URL, formData, {
      //       headers: {
      //         "Content-type": "multipart/form-data",
      //       },
      //     })
      //     .then((res) => {
      //       console.log(res.data.file_path, "image res");
      //       setProfileUrl(res.data.file_path);
      //     });
      // }
      convertTo1Base64(info.file.originFileObj);
    },
  };
  const handleChange = (value) => {
    setAlignment(value);
  };

  const convertTo1Base64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
        setProfileImg(fileReader.result);
      };
    });
  };

  // console.log(fileReader.result, "fileReader")

  // console.log(botDetails, "botDetails");

  const props1 = {
    onChange(info) {
      // console.log(info.file.originFileObj, "info");

      // const URL = `${BaseUrl}/upload_file?user_id=${UserID}&bot_id=${BotID}`;
      // const formData = new FormData();
      // formData.append("file", info.file.originFileObj);
      // if (info.file) {
      //   axios
      //     .post(URL, formData, {
      //       headers: {
      //         "Content-type": "multipart/form-data",
      //       },
      //     })
      //     .then((res) => {
      //       console.log(res.data.file_path, "image res");
      //       setBotProfileUrl(res.data.file_path);
      //     });
      // }
      convertToBase64(info.file.originFileObj);
    },
  };
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
        console.log(fileReader.result, "fileReader");
        setbotProfile(fileReader.result);
      };
    });
  };

  const props2 = {
    onChange(info) {

      convertTo2Base64(info.file.originFileObj);
    },
  };
  const convertTo2Base64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
        console.log(fileReader.result, "fileReader");
        setWelcomeImg(fileReader.result);
      };
    });
  };

  console.log(ProfileImg, "ProfileImg");

  // const success = (e) => {
  //   messageApi.open({
  //     type: "success",
  //     content: e,
  //   });
  // };
  // const error = (e) => {
  //   messageApi.open({
  //     type: "error",
  //     content: e,
  //   });
  // };

  console.log(type,'botTypeType')

  const handleSave = () => {
    let SaveUrl = `${BaseUrl}/bot_dynamic_details?user_id=${UserID}`;
    let body = {
      chatbot_name: BotName,
      chatbot_id: BotID[0],
      no_of_characters: 0,
      prompt: PromptMsg,
      profile_picture: ProfileImg,
      display_name: BotName,
      chat_conversation_colour: MessageClr,
      chat_icon: botProfile,
      chat_bubble_colour: BubbleClr,
      align_chat_bubble_button: Alignment,
      welcome_message: WelcomeMsg,
      welcome_image: welcomeImg,
      public_response: PublicRes,
      referance_page: reference,
      bot_type : type
    };
    axios
      .post(SaveUrl, body)
      .then((res) => {
        console.log(res.data, "saveRes");
        if (res.data.status === 404) {
          openNotificationWithIconError('Failed to save settings')
          // message.error("failed to save settings");
        }

        if( type === 'streamline-assistant' ){
          if (res.data.response == "New chatbot dynamic details stored") {
            openNotificationWithIconSuccess('Bot created successfully')
            // message.success("Bot created successfully");
          } else if (res.data.response == "Chatbot Dynamic Details Updated") {
            openNotificationWithIconSuccess('Settings updated')
            // message.success("Settings updated");
          } else {
            console.log(res.data.response, "");
          }
        } else{

          if (sourceFile || sourceText || sourceWeb == true) {
            if (res.data.response == "New chatbot dynamic details stored") {
              openNotificationWithIconSuccess('Bot created successfully')
              // message.success("Bot created successfully");
            } else if (res.data.response == "Chatbot Dynamic Details Updated") {
              openNotificationWithIconSuccess('Settings updated')
              // message.success("Settings updated");
            } else {
              console.log(res.data.response, "");
            }
          } else {
            openNotificationWithIconWarning('Please provide any source!')
            // message.warning("Please provide any source!");
          }
        }

      })
      .catch((err) => {
        console.log(err,'bot_dynamic_details_catch_err')
        openNotificationWithIconError(err.message);
        // message.error(err);
      });
  };
  useEffect(() => {
    axios
    .post(`${BaseUrl}/users_list?company_id=65ccfc27-7f3f-4ffd-aedf-fd07854a9670`)
    .then((res) => {
      console.log(res,"userlist")
      setuserList(res.data)
    })
    .catch((err) => {
      console.log(err)
    })
  }, [])
  console.log(typeof PublicRes, "PublicRes")
  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }
  const handleChange1 = (value) => {
    console.log(`Selected: ${value}`);
  };


  return (
    <div className="setting">
      <Row>
        <Col span={12}>
          {contextHolder}
          <div className="SettingsLeft">
            {/* <Row>
          <h3 className="chatBotID">Chatbot Id :</h3>
          <p className="chatID" style={{ marginTop: 12 }}>
            {BotID}
          </p>
        </Row> */}
            <h3 style={{ marginTop: 17 }}>Chatbot Name</h3>
            <Row>
              <Input
                className="ChatbotName"
                value={BotName}
                onChange={(e) => setBotName(e.target.value)}
                placeholder="Enter your Botname"
              />
            </Row>
            {BotName == '' && errMsg == true ?
              <Row>
                <Col span={24}>
                  <p className="errorMessage">Bot name is mandatory!</p>
                </Col>
              </Row>
              :
              <></>
            }
            <Row>
              <Col span={12}>
                <h3>Bubble Colour</h3>
              </Col>
              <Col span={12}>
                <h3>Message Colour</h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <ColorPicker
                  value={BubbleClr}
                  onChange={(e) => setBubbleClr(e.toHexString())}
                  // defaultValue="#6B24D6"
                  className="ColorPicker"
                />
              </Col>
              <Col>
                <ColorPicker
                  value={MessageClr}
                  // defaultValue="#fff"
                  onChange={(e) => setMessageClr(e.toHexString())}
                  className="ColorPicker"
                />
              </Col>
            </Row>

            <h3 style={{ marginTop: 17 }}>Chatbot Icon</h3>
            <Row>
              <Upload
                {...props1}
                customRequest={({ onSuccess }) => onSuccess("ok")}
                className="Upload"
              >
                <Button icon={<UploadOutlined />}> Upload</Button>
              </Upload>
              <p className="formatPara">{`(Supported Formats : png, jpg)`}</p>
            </Row>
            <h3 style={{ marginTop: 17 }}>Chatbot Profile Pic</h3>
            <Row>
              <Upload
                {...props}
                customRequest={({ onSuccess }) => onSuccess("ok")}
                className="Upload"
              >
                <Button icon={<UploadOutlined />}> Upload</Button>
              </Upload>
              <p className="formatPara">{`(Supported Formats : png, jpg)`}</p>
            </Row>
            {/* <h3 style={{ marginTop: 17 }}>Display Name</h3>
        <Row>
          <Input className="ChatbotName" placeholder="Enter Display Name" />
        </Row> */}
            <h3 style={{ marginTop: 17 }}>Align Chat Bubble Button</h3>
            <Row className="selectRow">
              <Select
                // defaultValue="Right"
                value={Alignment}
                onChange={handleChange}
                options={[
                  {
                    value: "Left",
                    label: "Left",
                  },
                  {
                    value: "Right",
                    label: "Right",
                  },
                ]}
              />
            </Row>
            <h3 style={{ marginTop: 17 }}>Acceptance Of Public Response</h3>
            <Row className="selectRow">
              <Switch style={{ marginTop: 5 }} checkedChildren="Yes" onChange={(e) => setPublicRes(e)} unCheckedChildren="No" defaultChecked={false} />
            </Row>
            {PublicRes == true ?
              <p className="errorMessage">Additional charges are applicable!</p>
              : <></>
            }
            <h3 style={{ marginTop: 17 }}>Reference Page no.</h3>
            <Row className="selectRow">
              <Switch style={{ marginTop: 5 }} checkedChildren="Yes" onChange={(e) => setReference(e)} unCheckedChildren="No" checked={reference} defaultChecked={false} />
            </Row>
            <h3 style={{ marginTop: 17 }}>Welcome Message</h3>
            <Row>
              <TextArea
                value={WelcomeMsg}
                // defaultValue={WelcomeMsg}
                onChange={(e) => setWelcomeMsg(e.target.value)}
                placeholder="Enter Welcome Message"
                rows={4}
              />
            </Row>
            <h3 style={{ marginTop: 17 }}>Welcome Image</h3>
            <Row>
              <Upload
                {...props2}
                customRequest={({ onSuccess }) => onSuccess("ok")}
                className="Upload"
              >
                <Button icon={<UploadOutlined />}> Upload</Button>
              </Upload>
              <p className="formatPara">{`(Supported Formats : png, jpg, jpeg)`}</p>
            </Row>
            {
              ShowAssignTo === true ?
                <>
                  <h3 style={{ marginTop: 17 }}>Assign To</h3>
                  <Select
                    mode="multiple"
                    size={'large'}
                    placeholder="Please select"
                    // defaultValue={['a10', 'c12']}
                    onChange={handleChange1}
                    style={{
                      width: 'auto',
                      minWidth: 180 
                    }}
                    options={userList}
                  />
                </>
                : <></>}

            {/* <h3 style={{ marginTop: 17 }}>Prompt</h3>
        <Row>
          <TextArea onChange={(e) => setPromptMsg(e.target.value)} placeholder="Enter Prompt(Prompt is a question or instruction that tells me what you want me to do)" rows={4} />
        </Row> */}
          </div>
        </Col>

        <Col span={12}>
          <div className="botPreview">
            <BotPreview
              Botname={BotName}
              BubbleClr={BubbleClr}
              WelcomeMsg={WelcomeMsg}
              ProfileImg={ProfileImg}
              botProfile={botProfile}
              MessageClr={MessageClr}
              Prompt={PromptMsg}
              PublicRes={PublicRes}
              reference = {reference}
              iconAlignment={Alignment}
              welcomeImg={welcomeImg}
            />
          </div>
        </Col>
        <Button className="settingSave" onClick={() => {
          if (BotName != '') {
            handleSave();
            setErrMsg(false)
          } else {
            openNotificationWithIconWarning('Bot name is mandatory!')
            setErrMsg(true)
          }
        }
        }>
          Save
        </Button>
      </Row>
    </div>
  );
};

export default Settings;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentEditor{
    margin-top: 50px;
    min-height: 100vh; 
}
.contentTable{
    padding: 60px;
}`, "",{"version":3,"sources":["webpack://./src/Components/ContentEditor/ContentEditor.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,aAAa;AACjB","sourcesContent":[".contentEditor{\n    margin-top: 50px;\n    min-height: 100vh; \n}\n.contentTable{\n    padding: 60px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from 'react'
import './Header.css'
import { Row, Col, Avatar, Popover, Button, message, notification } from 'antd'
import { CaretDownOutlined, UserOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useMemo } from 'react';
import axios from 'axios'
import { BaseUrl } from '../../Constants/Constant'
import { useStateValue } from '../Reducer/reducer';
import { actionTypes } from '../Reducer/stateProvider'

const Headers = ({ disabled }) => {
    const [
        { },
        dispatch,
    ] = useStateValue();
    const [chatBotClass, setChatBotClass] = useState('');
    const [pricingClass, setPricingClass] = useState('');
    const [addonClass, setAddonClass] = useState('');
    const [ManageClass, setManageClass] = useState("")
    const [BillingClass, setBillingClass] = useState("")
    const [showArrow, setShowArrow] = useState(true);
    const [arrowAtCenter, setArrowAtCenter] = useState(false);
    const [Login, setLogin] = useState(false)
    // const [messageApi, contextHolder] = message.useMessage();
    const [username, setusername] = useState("")

    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIconSuccess = (type) => {
        console.log('getsSuccess')
        api['success']({
          message: "Success!!!",
          description:
            type,
        });
      };
    
    const openNotificationWithIconError = (type) => {
        api['error']({
          message: "Oops!!!",
          description:
            type,
        });
      };
    
      const openNotificationWithIconWarning = (type) => {
        api['warning']({
          message: "Warning!!!",
          description:
            type,
        });
      };


    const [
        { IsAuthenticated },
        // dispatch,
    ] = useStateValue();

    console.log(username, "name");
    // console.log(UserName,"username")
    const navigate = useNavigate();

    let url = window.location.href;
    let specWord = url.split('/').slice(-1);

    let getPage = url.split('/')[3];

    console.log(getPage, 'getPage')


    useEffect(() => {
        if( getPage === 'create-bot' || getPage === 'settings' || getPage === 'create-type' ){
            setChatBotClass('activeNav');
        } else if( getPage === 'chatbot-builder' ){
            setManageClass('activeNav');
        } else{
            setChatBotClass('nonActiveNav');
            setManageClass('nonActiveNav');
        }
    },[])


    console.log(specWord[0], 'location')
    const mergedArrow = useMemo(() => {
        if (arrowAtCenter)
            return {
                pointAtCenter: true,
            };
        return showArrow;
    }, [showArrow, arrowAtCenter]);

    useEffect(() => {

        if (specWord[0] == 'chatbot') {
            setChatBotClass('activeNav');
            setPricingClass('nonActiveNav');
            setAddonClass('nonActiveNav')
        } else if (specWord[0] == 'pricing') {
            setChatBotClass('nonActiveNav');
            setPricingClass('activeNav');
            setAddonClass('nonActiveNav');
        } else if (specWord[0] == 'add-on') {
            setChatBotClass('nonActiveNav');
            setPricingClass('nonActiveNav');
            setAddonClass('activeNav');
        }
        else if (specWord[0] == 'my-chatbots') {
            setChatBotClass('nonActiveNav');
            setPricingClass('nonActiveNav');
            setAddonClass('nonActiveNav');
            setManageClass('activeNav');
        }
        else if (specWord[0] == 'billing') {
            setChatBotClass('nonActiveNav');
            setPricingClass('nonActiveNav');
            setAddonClass('nonActiveNav');
            setManageClass('nonActiveNav');
            setBillingClass('activeNav')
        }

    }, [specWord])
    // const error = (e) => {
    //     messageApi.open({
    //         type: 'error',
    //         content: e,
    //     });
    // };

    const handleLogout = () => {
        localStorage.setItem("IsAuthenticated", "false");
        localStorage.setItem("UserID", "");


        dispatch({
            type: actionTypes.SET_ISAUTHENTICATED,
            IsAuthenticated: "false"
        })
        console.log("authfalse")
        navigate("/Login")

    }

    const handleAccount = () => {
        navigate('/account')
    }
    const handleSettings = () => {
        navigate('/userSetting')
    }

    //=========== Popover Content
    const content = (
        <>
            <div className='headerAccountDiv'>
                <p className='logoutPopoverContent' onClick={handleAccount}>Account</p>
            </div>
            <div className='headerAccountDiv'>
                <p className='logoutPopoverContent' onClick={handleSettings}>Settings</p>
            </div>
            <div className='logoutPopover' onClick={handleLogout} >
                <p className='logoutPopoverContent'>Logout</p>
                <img className='LogoutImg' src='/login/logout1.png' alt='Logout' width={16} height={16} />
            </div>

        </>
    );
    const handleCreateBot = () => {
        if (IsAuthenticated == "false") {
            // error('Please Login to continue');
            openNotificationWithIconError('Please Login to continue');
            setTimeout(() => {
                navigate('/Login')
            }, 1000)
        }
        if (IsAuthenticated == "true") {
            window.localStorage.setItem('currentPage', 'Create');
            
            dispatch({
                type: actionTypes.SET_ENTEREDTYPE,
                enteredType: "Create"
              })

            dispatch({
                type: actionTypes.SET_SOURCEFILE,
                sourceFile: false
            })

            dispatch({
                type: actionTypes.SET_SOURCETEXT,
                sourceText: false
            })

            dispatch({
                type: actionTypes.SET_SOURCEWEB,
                sourceWeb: false
            })
            dispatch({
                type: actionTypes.SET_BOTDETAILS,
                botDetails: {},
            });

            const UserID = localStorage.getItem("UserID");
            const CompanyID = localStorage.getItem("CompanyID");
            let url = `${BaseUrl}/display_userplan_details`
            let body = {
                "company_id": CompanyID
            }
            axios
                .post(url, body)
                .then((res) => {
                    console.log(res.data, "chan")
                    if (!res.data.plan_name) {
                        openNotificationWithIconError('Please choose your plan to continue');
                        // error('Please choose your plan to continue');
                        setTimeout(() => {
                            navigate('/pricing')
                        }, 1000)
                    }
                    else {

                        navigate('/create-type')
                        // let CreateBotURL = `${BaseUrl}/Bot_ID`
                        // axios
                        //     .post(CreateBotURL)
                        //     .then((res) => {
                        //         if (res.data.response) {
                        //             localStorage.setItem("BotID", res.data.response);
                        //             navigate(`/chatbot-builder/${res.data.response}`)

                        //         }
                        //     })
                    }
                })
        }
    }
    const HandleManageBot = () => {
        if (IsAuthenticated == "false") {
            // error('Please Login to continue');
            openNotificationWithIconError('Please Login to continue');
            setTimeout(() => {
                navigate('/Login')
            }, 1000)
        }

        if (IsAuthenticated == "true") {
            navigate('/my-chatbots')
        }
    }
    useEffect(() => {
        if (IsAuthenticated == "false") {
            setLogin(true)
        }
        if (IsAuthenticated == null) {
            setLogin(true)
        }
        if (IsAuthenticated == "true") {
            setLogin(false)
            const UserName = localStorage.getItem("Email");
            setusername(UserName.match(/^([^@]+)/)[1])
        }
    }, [IsAuthenticated])

    const handleLogin = () => {
        navigate('/Login')
    }

    const Settingscontent = (
        <div className='Chatbot-Popover'>

            <Row>

                <Button onClick={handleCreateBot} className='CreateChatBot-Link' type='link'>Get Precium</Button>
            </Row>
            <Button onClick={HandleManageBot} className='ManageChatBot-link' type='link'>Manage Precium</Button>
        </div>
    )
    const text = <span>Title</span>;
    return (
        <div  className={disabled == true  ? 'headers-disabled' : 'mainHeader'} >
            {contextHolder}
            <Row className='mainHeaderRow'>
                <Col span={7} className='headerLogoCol'>
                    <img src='\Home\Precium-logo.png' width={140} height={20}
                        onClick={() => {

                            navigate('/')
                        }}
                        style={{ cursor: 'pointer' }}
                    />
                </Col>
                <Col span={16} className='headerHeadings'>
                    <Row className='headerHeadingsInsideRow'>
                        <Col span={5} onClick={handleCreateBot} className={chatBotClass}>
                            {/* <Popover trigger="hover" placement="bottomRight" content={Settingscontent} arrow={mergedArrow}> */}

                            <h3
                            >Get Precium </h3>
                            {/* </Popover> */}
                        </Col>
                        <Col span={5} onClick={HandleManageBot} className={ManageClass}>
                            {/* <Popover trigger="hover" placement="bottomRight" content={Settingscontent} arrow={mergedArrow}> */}

                            <h3
                            >Manage Precium </h3>
                            {/* </Popover> */}
                        </Col>
                        {/* <Col span={3} className={pricingClass} >

                            <h3
                                onClick={() => {
                                    // navigate('/pricing')
                                }}
                            >Plans
                            </h3>

                        </Col> */}
                        {/* <Col span={3} className={BillingClass} >

                            <h3
                                onClick={() => {
                                    navigate('/billing')
                                }}
                            >Billing</h3>

                        </Col> */}
                        <Col span={3} className={addonClass}>
                            <h3>+Apps</h3>
                        </Col>
                        <Col span={5} className='avatarCol' >
                            {
                                Login === true ?

                                    <Button onClick={handleLogin} className='Header-login'>
                                        Login
                                    </Button>
                                    :
                                    <>
                                        <Avatar size={30} icon={<UserOutlined />} />
                                        <Popover content={content} placement='bottomRight' trigger='click' >
                                            <div style={{ display: 'flex', margin: 0 }}>
                                                <h3>{username}</h3>
                                                <CaretDownOutlined className='avatarDownArrow' />
                                            </div>
                                        </Popover>

                                    </>
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default Headers
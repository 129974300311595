// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Fonts/Kanit-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Fonts/Kanit-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./Fonts/static/OpenSans-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  max-width: 1350px;
  padding: 0px;
  margin: 0 auto;
  font-family: Kanit;
}
body {
  margin: 0;
  font-family: Kanit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root{
  min-height: 100%;
  position: relative;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: "Kanit";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}
@font-face {
  font-family: "Kanitmedium";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("truetype");
}
@font-face {
  font-family: "open-sans-regular";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("truetype");
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;EACZ,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,SAAS;EACT,kBAAkB;EAClB,mCAAmC;EACnC,kCAAkC;AACpC;AACA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE;aACW;AACb;AACA;EACE,oBAAoB;EACpB,+DAAwD;AAC1D;AACA;EACE,0BAA0B;EAC1B,+DAAuD;AACzD;AACA;EACE,gCAAgC;EAChC,+DAAkE;AACpE","sourcesContent":["*{\n  max-width: 1350px;\n  padding: 0px;\n  margin: 0 auto;\n  font-family: Kanit;\n}\nbody {\n  margin: 0;\n  font-family: Kanit;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n#root{\n  min-height: 100%;\n  position: relative;\n}\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n@font-face {\n  font-family: \"Kanit\";\n  src: url('./Fonts/Kanit-Regular.ttf') format(\"truetype\");\n}\n@font-face {\n  font-family: \"Kanitmedium\";\n  src: url('./Fonts/Kanit-Medium.ttf') format(\"truetype\");\n}\n@font-face {\n  font-family: \"open-sans-regular\";\n  src: url('./Fonts/static/OpenSans-Regular.ttf') format(\"truetype\");\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

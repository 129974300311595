import React from 'react'
import './Home.css'
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'antd'
import { useNavigate } from 'react-router';
import Headers from '../Header/Headers'
import axios from 'axios';
import Footer from '../Footer/Footer';
import { BaseUrl } from '../../Constants/Constant';
import { message, notification } from 'antd';
import { actionTypes } from '../Reducer/stateProvider';
import { useStateValue } from '../Reducer/reducer';

const Home = () => {

  // const [messageApi, contextHolder] = message.useMessage();
  const [Response, setResponse] = useState()
  const [
    { IsAuthenticated },
    dispatch,
  ] = useStateValue();

  const navigate = useNavigate();

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIconSuccess = (type) => {
    console.log('getsSuccess')
    api['success']({
      message: "Success!!!",
      description:
        type,
    });
  };

  const openNotificationWithIconError = (type) => {
    api['error']({
      message: "Oops!!!",
      description:
        type,
    });
  };

  const openNotificationWithIconWarning = (type) => {
    api['warning']({
      message: "Warning!!!",
      description:
        type,
    });
  };


  // const error = (e) => {
  //   messageApi.open({
  //     type: 'error',
  //     content: e,
  //   });
  // };
  const handleClick = () => {

    if (IsAuthenticated === "true") {

      window.localStorage.setItem('currentPage', 'Create')

      dispatch({
        type: actionTypes.SET_ENTEREDTYPE,
        enteredType: "Create"
      })

      dispatch({
        type: actionTypes.SET_SOURCEFILE,
        sourceFile: false
      })

      dispatch({
        type: actionTypes.SET_SOURCETEXT,
        sourceText: false
      })

      dispatch({
        type: actionTypes.SET_SOURCEWEB,
        sourceWeb: false
      })
      dispatch({
        type: actionTypes.SET_BOTDETAILS,
        botDetails: {},
      });
      const UserID = localStorage.getItem("UserID");
      const CompanyID = localStorage.getItem("CompanyID");
      let url = `${BaseUrl}/display_userplan_details`
      let body = {
        "company_id": CompanyID
      }
      axios
        .post(url, body)
        .then((res) => {
          console.log(res.data, "chan")
          if (!res.data.plan_name) {
            openNotificationWithIconError('Please choose your plan to continue');
            // error('Please choose your plan to continue')
            setTimeout(() => {
              navigate('/pricing')
            }, 1000)
          }
          else {
            navigate('/create-type')
            // let CreateBotURL = `${BaseUrl}/Bot_ID`
            // axios
            //   .post(CreateBotURL)
            //   .then((res) => {
            //     if (res.data.response) {
            //       localStorage.setItem("BotID", res.data.response);
            //       navigate(`/chatbot-builder/${res.data.response}`)

            //     }
            //   })
          }
        })


    }
    else {
      openNotificationWithIconError('Please Login to continue');
      // error('Please Login to continue')
      setTimeout(() => {
        navigate('/Login')
      }, 1000)
    }
  }


  return (
    <>
      <Headers />
      {contextHolder}
      <div className='homeMainClass'>
        <Row className='homeMainClassRow'>
          <Col span={8} className='homeMainClassCol'>
            <div className='homeContent'>
              <h2 className='homeHeading'>Empower Your Digital Presence with Conversational Intelligence</h2>
              <h3 className='homeContentDesc'>Unleash the Power of Chatbots to Transform Customer Interactions and Boost Engagement</h3>
              <Button
                onClick={handleClick}
                className='createChatbotBtn'>Get Precium</Button>
            </div>
          </Col>
          <Col className='homeSiderImage' span={16}>

          </Col>
        </Row>
      </div>

      <Footer />
    </>
  )
}

export default Home
import { Button, Col, Input, Row, message, notification } from "antd";
import React, { useState } from "react";
import { useStateValue } from "../../../Reducer/reducer";
import { actionTypes } from "../../../Reducer/stateProvider";
import { BaseUrl, MicroUrl1, MicroUrl2 } from "../../../../Constants/Constant";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import './Website.css'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'; 

const Website = () => {
  const [{ sourceWeb, sourceDataWebsite }, dispatch] = useStateValue();

  const navigate = useNavigate();
  let { type } = useParams();

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIconSuccess = (type) => {
    console.log('getsSuccess')
    api['success']({
      message: "Success!!!",
      description:
        type,
    });
  };

const openNotificationWithIconError = (type) => {
    api['error']({
      message: "Oops!!!",
      description:
        type,
    });
  };

  const openNotificationWithIconWarning = (type) => {
    api['warning']({
      message: "Warning!!!",
      description:
        type,
    });
  };


  // const [webLink, setWebLink] = useState( sourceDataWebsite[0]?.file_name || "");
  const [ webLink, setWebLink ] = useState( sourceDataWebsite && sourceDataWebsite.length > 0 ? sourceDataWebsite[0].text_or_link : "" )


  const UserID = localStorage.getItem("UserID");
  let url = window.location.href; 
  let BotID = url.split('/').slice(-1)

  const handleSave = () => {
    enterLoading(0)
  
    const URL = `${MicroUrl1}/upload_link_content?link=${webLink}&user_id=${UserID}&bot_id=${BotID[0]}`;
    // const URL = `${BaseUrl}/upload_link_content?link=${webLink}&user_id=${UserID}&bot_id=${BotID[0]}`;

    if (webLink != "") {
      axios.post(URL)
      .then((res) => {
        console.log(res.data.response, "WebLink");
        if (res.data.status === 404){
          dispatch({
            type: actionTypes.SET_WEBSOURCE,
            webSource: res.data.response,
          });
          navigate('/GetBackSoon')
          
          // message.error('Your website is more secured so please wait 24hrs to get your Bot ready')
        }

        if (res.data.status == 200) {
          let filePath = res.data.file_path;

          openNotificationWithIconSuccess("Weblink saved Successfully");
              // message.success("Weblink saved Successfully");
              dispatch({
                type: actionTypes.SET_SOURCEWEB,
                sourceWeb: true,
              });
              setTimeout(() => {
                navigate(`/settings/${type}/${BotID[0]}`);
              }, 2000)
              stopLoading(0);

        //   let body ={
        //     user_id:UserID,
        // bot_id: BotID[0],
        // file_path: filePath
        //   }
        //   let vectorUrl = `${MicroUrl2}/vector_creation/files`;
        //   // let vectorUrl = `${BaseUrl}/vector_creation/files`;

        //   axios.post(vectorUrl,body)
        //   .then((res) => {
        //     console.log(res, "webVectorCreation");

        //     if (res.data.status == 200) {
        //       openNotificationWithIconSuccess("Weblink saved Successfully");
        //       // message.success("Weblink saved Successfully");
        //       dispatch({
        //         type: actionTypes.SET_SOURCEWEB,
        //         sourceWeb: true,
        //       });
        //       navigate(`/settings/${BotID[0]}`);
        //       stopLoading(0);
        //     }
        //     else{
        //       stopLoading(0);
        //       openNotificationWithIconError(res.data.response);
        //       // message.error(res.data.response);
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //     openNotificationWithIconError(err.message);
        //     // message.error(err)
        //   })
        }
      })
      .catch(( err ) => {
        stopLoading(0);
        console.log(err);
        openNotificationWithIconError('Error Creating Vector!')
      })
    } else {
      stopLoading(0);
      openNotificationWithIconWarning("Please put any website link");
      // message.warning("Please put any website link");
      dispatch({
        type: actionTypes.SET_SOURCEWEB,
        sourceWeb: false,
      });
      
    }
  };

  const [loadings, setLoadings] = useState([]);
  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 60000);
  };
  const stopLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = false;
      return newLoadings;
    });
  };

  // console.log(sourceWeb,'sourceWeb')

  return (
    <div>
      {contextHolder}
      <Row>
        <Col span={24}>
          <h4>Crawl through Website</h4>
          <Input
            value={webLink}
            onChange={(e) => setWebLink(e.target.value)}
            className="sourceInput"
            placeholder="https://www.example.com"
          />
        </Col>
      </Row>
      <Row className="websiteSaveRow">
        <Col span={4}>
        <Button
            // disabled={!Text ? true:false}
            onClick={() => navigate('/create-type')}
            // loading={loadings[0]}
            className="createSettingSave"
          >
           <LeftOutlined /> Back
          </Button>
        </Col>
        <Col span={4}>
          <Button onClick={handleSave}  loading={loadings[0]} className="createSettingSave">
            Next <RightOutlined />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default Website;

import React ,{useState,useEffect}from 'react'
import { useNavigate } from 'react-router'
import { LinkedinFilled } from '@ant-design/icons';
import './Footer.css'
import axios from 'axios';
import { BaseUrl } from '../../Constants/Constant';
const Footer = () => {

    const navigate = useNavigate();   
  const [Response, setResponse] = useState({})
    useEffect(() => {
        axios
        .get(`${BaseUrl}/get_settings`)
        .then((res) => {
         setResponse(res.data.settings.settings_data[0])
         console.log(res.data.settings.settings_data[0],"resssssss")
        })
        .catch((err) => {
            console.log(err)
        })
       }, [])
    return (
        <footer>
            <div className='fullFooter'>
                <div className='footerFullContent'>
                    <div className='leftFooter'>
                        <div className='footerLogoVersion'>
                            <img src='\Home\Precium-logo.png' width={140} height={20}
                                onClick={() => {
                                    navigate('/')
                                }}
                                style={{ cursor: 'pointer' }}
                            />
                            <p>v2.0</p>
                        </div>

                    </div>
                    <div className='rightFooter'>
                        <div>
                            <LinkedinFilled onClick={() => window.open(Response.linkedin, '_blank')} />
                        </div>
                    </div>
                </div>
                <hr></hr>
                <div className='footerContact' >
                    <div className='contactLeft'>
                        <p>Contact: <a className='footerMail' href='mailto:info@valuehealthsol.com'>info@valuehealthsol.com</a></p>
                    </div>
                    <div className='policyTerms'>
                        {/* <p onClick={ () => navigate('/privacy') }>Privacy Policy</p>
                        <p onClick={ () => navigate('/terms') }>Terms of Service</p> */}
                        <a href={Response.info} target='_blank'>Powered By Value Health Inc.</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
import React, { useState,useEffect } from 'react'
import Headers from '../Header/Headers'
import Footer from '../Footer/Footer'
import './Account.css'
import axios from 'axios'
import { BaseUrl } from '../../Constants/Constant'
import { Row, Col, Card, Button } from 'antd'

const Account = () => {
  const UserName = localStorage.getItem("Email");
  const UserID = localStorage.getItem("UserID");
  const CompanyID = localStorage.getItem("CompanyID");
  const [plan, setplan] = useState("")

  useEffect(() => {
    let url =`${BaseUrl}/display_userplan_details`
    let body = {
      "company_id": CompanyID
    }
   axios
   .post(url,body)
   .then((res) => {
    if(res.data.status == 200){

      setplan(res.data.plan_name)
    }
console.log(res,"ressplan")
   })
   .catch((err) => {
    console.error('error')
   })
  }, [])
  return (
    <>
    <Headers />
    <div className='accountMainDiv'>
      <Row>
        <Col offset={3} span={18}>
          <h1 className='CreatechatBot'>Account</h1>
          <div className='accountCardsFullDiv'>
            <div className='accountCardsDiv'>
            <Card>
              <div className='accountCard'>
              <div className='headingDiv'>
              <h3 className='cardHeading'>Current Plan</h3>
              <h3 className='accountPlan'>{plan}</h3>
              </div>
              <div className='upgradeButtonDiv'>
                <Button className='upgradeButton'>{plan == "Corporate" ? "Current plan" : "Upgrade" }</Button>
              </div>
              </div>
            </Card>
            </div>
            <div className='accountCardsDiv'>
            <Card>
              <h3 className='cardHeading'>Usage</h3>
              <p className='cardcontents'>Messages consumed : 80/80</p>
              <p className='cardcontents'>Next Renewal : July 1st</p>
              <p className='creditContent'>{`(Your credits will renew at the start of every calendar month)`}</p>
            </Card>
            </div>
            <div className='accountCardsDiv'>
            <Card>
              <div className='accountCard'>
              <div className='headingDiv'>
              <h3 className='cardHeading'>Email Id</h3>
              <p className='cardcontents'>{UserName}</p>
              </div>
              <div className='upgradeButtonDiv'>
                <Button className='accountEditBtn'>Edit</Button>
              </div>
              </div>
            </Card>
            </div>
            <div className='accountCardsDiv'>
            <Card>
              <h3 className='cardHeading'>Api Key</h3>
              <p className='cardcontents'>4fe35c8d-63c5-4ab5-acfd-367504697fdb</p>
            </Card>
            </div>
          </div>
        </Col>
      </Row>
      <div className='accountTwoButtons'>
        <Button className='accountDeleteBtn' danger>Delete Account</Button>
        <Button className='accountLogoutBtn'>Logout</Button>
      </div>
    </div>
    <Footer />
    </>
  )
}

export default Account
import { Col, Row, Input, Button, message, Form, Tooltip, notification } from 'antd'
import './Signup.css'
import { MailOutlined, LockOutlined, QuestionCircleOutlined, TeamOutlined, UserOutlined, IdcardOutlined } from '@ant-design/icons'
import React ,{useEffect}from 'react'
import axios from 'axios'
import { useState } from 'react'
import { useNavigate } from 'react-router';
import { BaseUrl } from '../../Constants/Constant';
import { useStateValue } from '../Reducer/reducer';
import { actionTypes } from '../Reducer/stateProvider'
import { Link } from 'react-router-dom'


const Signup = () => {
  const [
    { },
    dispatch,
  ] = useStateValue();
  const [Email, setEmail] = useState("")
  const [Password, setPassword] = useState("")
  const [ConfirmPass, setConfirmPass] = useState("")
  const [CompanyName, setCompanyName] = useState("")
  const [FirstName, setFirstName] = useState("")
  const [LastName, setLastName] = useState("")
  const [CompanyStrength, setCompanyStrength] = useState("")
  const [IndustryType, setIndustryType] = useState("")
  const navigate = useNavigate()
  // const [messageApi, contextHolder] = message.useMessage();
  const [pwdStatus, setPwdStatus] = useState('');
  const [CpwdStatus, setCPwdStatus] = useState('');
  const [userList, setuserList] = useState([])
  const handleLogin = () => {
    console.log("login")
    navigate("/Login")
  }

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIconSuccess = (type) => {
    console.log('getsSuccess')
    api['success']({
      message: "Success!!!",
      description:
        type,
    });
  };

const openNotificationWithIconError = (type) => {
    api['error']({
      message: "Oops!!!",
      description:
        type,
    });
  };

  // const success = (e) => {
  //   messageApi.open({
  //     type: 'success',
  //     content: e,
  //   });
  // };
  // const error = (e) => {
  //   messageApi.open({
  //     type: 'error',
  //     content: e,
  //   });
  // };
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    // Password validation regex pattern
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
    const isValidPassword = passwordRegex.test(value);

    // Update password status based on validation
    setPwdStatus(isValidPassword ? 'success' : 'error');
    console.log(isValidPassword, "isValidPassword")
  };
  const handlePasswordChange1 = (e) => {
    const value = e.target.value;
    setConfirmPass(value);

    // Password validation regex pattern
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
    const isValidPassword = passwordRegex.test(value);

    // Update password status based on validation
    setCPwdStatus(isValidPassword ? 'success' : 'error');
    console.log(isValidPassword, "isValidPassword")
  };
  const handleSignup = () => {
    localStorage.setItem("Email", Email);
    localStorage.setItem("isAuthenticated", false);
    if(CompanyName.length && CompanyStrength.length && IndustryType.length != 0 ) {

    

    let SIGNUPURL = `${BaseUrl}/signup`
    let signupBody = {
      "company_name": CompanyName,
      "company_strength": CompanyStrength,
      "industry_type": IndustryType,
      "first_name": FirstName,
       "last_name": LastName,
       "email": Email.toLowerCase(),
       "password": Password,
       "role": "Admin",
       "otp_verified": 1
    }
    axios
      .post(SIGNUPURL, signupBody)
      .then((res) => {
        console.log(res, "chanres")
        if (res.data.status == 200) {
          
              openNotificationWithIconSuccess('SignUp Sucess');
          dispatch({
            type: actionTypes.SET_ISAUTHENTICATED,
            IsAuthenticated: "true"
          })
          localStorage.setItem("Email", CompanyName);
    localStorage.setItem("CompanyID",res.data.company_id );
    localStorage.setItem("UserID", res.data.user_id);
    // localStorage.setItem("CompanyID",res.data. );
    navigate("/userSetting")
          // let affiliationURL = `${BaseUrl}/user_affiliation`
          // let body = {
          //   "company_id": res.data.company_id,
          //   "first_name": FirstName,
          //   "last_name": LastName,
          //   "email": Email.toLowerCase(),
          //   "password": ConfirmPass,
          //   "role": "super admin",
          //   "otp_verified": 0
          // }
          // axios
          //   .post(affiliationURL, body)
          //   .then((res) => {
          //     console.log(res, "channnnn")
          //     if (res.data.status == 200) {

          //       success(res.data.response)
          //       navigate("/login")
          //     }
          //     else {
          //       error(res.data.response)
          //     }
          //   })
        } else {
          openNotificationWithIconError(res.data.response);
          // error(res.data.response);
        }
      })
    }
    else{
      
      openNotificationWithIconError('please fill all the fields');
    }


    //  if (pwdStatus === 'error'){

    //   // error("Invalid password")
    //  }else{
    //   if(isValidEmail(Email) === true){
    //     let SIGNUPURL = `${BaseUrl}/sign_up`
    //     let Body = {
    //       email: Email.toLowerCase(),
    //       password: ConfirmPass,
    //       first_name: "string", 
    //       last_name: "string"
    //     }
    //     axios
    //       .post(SIGNUPURL, Body )
    //       .then((res) => {

    //         if (res.data.status === 200){
    //           console.log(res)
              
    //           openNotificationWithIconSuccess('SignUp Sucess');
    //       localStorage.setItem("UserID", res.data.user_id);
    //           // navigate("/Login")
    //         }else {
    //           // error(res.data.response)
    //         }
    //       })
    //     console.log("signup")
    //  }}
    //  if(isValidEmail(Email) === false){

    //   // error("Invalid Email")
    //  }

  }
  function isValidEmail(email) {
    // Regular expression pattern for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }


  return (
    <div>
      {contextHolder}
      <Row>
        <Col flex={0} style={{ margin: 0 }} span={12}>
          <img src='/Home/Precium-logo.png' className='headerLogoOnly' />
          <img src='/login/sidePane.svg' className='loginImage' alt='SidePaneImage' />
        </Col>
        <Col span={10}   >



          <div className='logindiv'>
            <h1 className='LoginH1'>
              Signup
            </h1>
            <div className='Signup-Div'>
              <div className='signupInputs'>

              
              <Input
                prefix={<IdcardOutlined className='mailIcon' />}
                className='SignupInput'
                onChange={(e) => setCompanyName(e.target.value)}
                placeholder="Company Name" />

              <Input
                prefix={<TeamOutlined className='mailIcon' />}
                className='SignupInput'
                type='number'
                onChange={(e) => setCompanyStrength(e.target.value)}
                placeholder="Company strength" />
              <Input
                prefix={<TeamOutlined className='mailIcon' />}
                className='SignupInput'
                onChange={(e) => setIndustryType(e.target.value)}
                placeholder="Industry type" />
                <Row>
                  <Col span={11}>
                  <Input
                prefix={<UserOutlined className='mailIcon' />}
                className='SignupInput'
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name" />
                  </Col>
                  <Col span={11} offset={2}>
                  <Input
                prefix={<UserOutlined className='mailIcon' />}
                className='SignupInput'
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name" />
                  </Col>
                </Row>
             
             
              {/* <Input
              prefix={<TeamOutlined className='mailIcon' />}
              className='SignupInput'
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Role" /> */}

              <Input
                prefix={<MailOutlined className='mailIcon' />}
                className='SignupInput'
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email ID" />
              <Form.Item className='PasswordForm' hasFeedback validateStatus={pwdStatus}>
                <Input.Password
                  prefix={<LockOutlined className='mailIcon' />}
                  className='SignupInput'
                  status={!ConfirmPass ? "" : Password !== ConfirmPass ? "error" : ""}
                  onChange={handlePasswordChange}
                  placeholder="Password" type='password' />


                {pwdStatus === 'error' && (
                  <Tooltip title="Password must contain 8 characters, one lowercase letter, one digit, and one special character">
                    <QuestionCircleOutlined className="infoIcon" />
                  </Tooltip>
                )}
              </Form.Item>

              <div className='forgotpassDiv'>
                {/* <Form.Item className='PasswordForm' hasFeedback validateStatus={CpwdStatus}>
                  <Input.Password
                    status={!ConfirmPass ? "" : Password !== ConfirmPass ? "error" : ""}
                    prefix={<LockOutlined className='mailIcon' />}
                    className='SignupInput'

                    onChange={handlePasswordChange1}
                    placeholder="Confirm Password" type='password' />

                  {CpwdStatus === 'error' && (
                    <Tooltip title="Password must contain 8 characters, at least one uppercase letter, one lowercase letter, one digit, and one special character">
                      <QuestionCircleOutlined className="infoIcon" />
                    </Tooltip>
                  )}



                </Form.Item> */}
                {/* <Button className='forgotpassword' size='small' type='link'>
            Forgot Password?
          </Button> */}
              </div>
            </div>

          </div>
          </div>
          <div className='signupbuttondiv'>
            <Row>
              <Button
                onClick={handleSignup}
                // disabled={CompanyName || CompanyStrength != "" ? true : false}
                className='signupButton'>
                Signup
              </Button>


            </Row>
            <p className='ortag1'>------------ or ------------</p>
            <Row>
              {/* <Button
                onClick={handleLogin}
                className='signUpButton'>
                Login
              </Button> */}
              <p style={{ marginLeft: 100 }}>Already have an account? <Link to='/Login' ><span style={{ color: '#b670d0' }} >Login here</span></Link></p>

            </Row>

          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Signup
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer{
    position: absolute;
    /* bottom: 0; */
    width: 100%;
}
.fullFooter{
    padding: 0 1.5rem;
    background: #f4f4f5;
}
.footerFullContent{
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}
.leftFooter{
    margin: 0;
}
.rightFooter{
    margin: 0;
}
.footerContact{
    display: flex;
    padding: 10px 0;
    color: #52525b;
    font-size: 0.875rem;
    justify-content: space-between;
}
.footerContact a{
    text-decoration: none;
    color: #52525b;
    font-size: 0.875rem;
}
.contactLeft{
    margin: 0;
}
.policyTerms{
    margin: 0;
    display: flex;
}
.policyTerms p{
    font-size: .875rem;
    font-weight: 400;
    color: #52525b;
    cursor: pointer;
    margin-left: 40px;
}
.rightFooter .anticon svg{
    font-size: 24px;
    border-radius: 8px;
    color: #0a66c2;
    cursor: pointer;
}
.footerLogoVersion{
    display: flex;
}
.footerLogoVersion p{
    margin: 7px 0 0 4px;
    font-size: 11px;
    color: #fe602f;
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,WAAW;AACf;AACA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,eAAe;AACnB;AACA;IACI,SAAS;AACb;AACA;IACI,SAAS;AACb;AACA;IACI,aAAa;IACb,eAAe;IACf,cAAc;IACd,mBAAmB;IACnB,8BAA8B;AAClC;AACA;IACI,qBAAqB;IACrB,cAAc;IACd,mBAAmB;AACvB;AACA;IACI,SAAS;AACb;AACA;IACI,SAAS;IACT,aAAa;AACjB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,kBAAkB;IAClB,cAAc;IACd,eAAe;AACnB;AACA;IACI,aAAa;AACjB;AACA;IACI,mBAAmB;IACnB,eAAe;IACf,cAAc;AAClB","sourcesContent":["footer{\n    position: absolute;\n    /* bottom: 0; */\n    width: 100%;\n}\n.fullFooter{\n    padding: 0 1.5rem;\n    background: #f4f4f5;\n}\n.footerFullContent{\n    display: flex;\n    justify-content: space-between;\n    padding: 10px 0;\n}\n.leftFooter{\n    margin: 0;\n}\n.rightFooter{\n    margin: 0;\n}\n.footerContact{\n    display: flex;\n    padding: 10px 0;\n    color: #52525b;\n    font-size: 0.875rem;\n    justify-content: space-between;\n}\n.footerContact a{\n    text-decoration: none;\n    color: #52525b;\n    font-size: 0.875rem;\n}\n.contactLeft{\n    margin: 0;\n}\n.policyTerms{\n    margin: 0;\n    display: flex;\n}\n.policyTerms p{\n    font-size: .875rem;\n    font-weight: 400;\n    color: #52525b;\n    cursor: pointer;\n    margin-left: 40px;\n}\n.rightFooter .anticon svg{\n    font-size: 24px;\n    border-radius: 8px;\n    color: #0a66c2;\n    cursor: pointer;\n}\n.footerLogoVersion{\n    display: flex;\n}\n.footerLogoVersion p{\n    margin: 7px 0 0 4px;\n    font-size: 11px;\n    color: #fe602f;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState,useEffect } from 'react'
import Footer from '../Footer/Footer'
import './Billing.css'
import { jsPDF } from "jspdf";
import { useNavigate } from "react-router";
import axios from 'axios'
import { BaseUrl } from '../../Constants/Constant'
import Headers from '../Header/Headers'
import { Card,Row,Col,Button,Switch } from 'antd'

const Billing = () => {
    const [plan, setplan] = useState("")
    const UserName = localStorage.getItem("Email");
  const UserID = localStorage.getItem("UserID");
  const CompanyID = localStorage.getItem("CompanyID");
  const navigate = useNavigate();
  const doc = new jsPDF();

    useEffect(() => {
      let url =`${BaseUrl}/display_userplan_details`
      let body = {
        "company_id": CompanyID
      }
     axios
     .post(url,body)
     .then((res) => {
      if(res.data.status == 200){

        setplan(res.data.plan_name)
      }
  console.log(res,"ressplan")
     })
     .catch((err) => {
      console.error('error')
     })
    }, [])
    const onChange = (checked) => {
        console.log(`switch to ${checked}`);
      };
      const HandleButton = (e) => {
console.log(e.target.innerHTML)
if(e.target.innerHTML == "Upgrade"){
  navigate('/pricing')
} 
      }

      const DownloadInvoice = () => {
        doc.text("Hello world!", 1, 1);
doc.save("sample.pdf");
      }
  return (<>
        <Headers/>
    <div className='Billing-main'>
       <Row>
        <Col offset={3} span={18}>
            <h1 className='CreatechatBot'>
Billing
            </h1>
            <div className='accountCardsFullDiv'>
            <div className='accountCardsDiv'>

           
            <Card>
            <div className='accountCard'>
            <div className='headingDiv'>
              <h3 className='cardHeading'>Current Plan</h3>
              <h3 className='accountPlan'>{plan}</h3>
              </div>
              <div className='upgradeButtonDiv'>
                <Button 
                onClick={(e)=> HandleButton(e)}
                className='upgradeButton'>{plan == "Corporate" ? "Current plan" : "Upgrade" }</Button>
              </div>
              </div>
            </Card>
            </div>
            </div>
            <div className='accountCardsFullDiv'>
            <div className='accountCardsDiv'>
            <Card>
            <div className='accountCard'>
            <div className='headingDiv'>
            <h3 className='cardHeading'>Last Paid</h3>
            <h3 className='accountPlan'>17-7-2023</h3>
                </div>    
                </div>    
            </Card>
            </div>    
                </div>  
                <div className='accountCardsFullDiv'>
            <div className='accountCardsDiv'>
            <Card>
            <div className='accountCard'>
            <div className='headingDiv'>
            <h3 className='cardHeading'>Plan Start Date</h3>
            <h3 className='accountPlan'>17-7-2023</h3>
                </div>    
                </div>    
            </Card>
                </div>      
                </div>    
                <div className='accountCardsFullDiv'>
            <div className='accountCardsDiv'>
            <Card>
            <div className='accountCard'>
            <div className='headingDiv'>
            <h3 className='cardHeading'>Expiry Date</h3>
            <h3 className='accountPlan'>17-8-2023</h3>
                </div>    
                </div>    
            </Card>
                </div>    
                </div>    
                <div className='accountCardsFullDiv'>
            <div className='accountCardsDiv'>
            <Card>
            <div className='accountCard'>
            <div className='headingDiv'>
            <h3 className='cardHeading'>Auto Credit  (every month)</h3>
            <div className='upgradeButtonDiv'>
            <Switch defaultChecked onChange={onChange} style={{backgroundColor:'#b670d0'}} />
              </div>
              
                </div>  
                <div className='upgradeButtonDiv'>
                <Button 
                className='upgradeButton' onClick={DownloadInvoice}>Download Invoice</Button>
              </div>  
                </div>    
            </Card>
                </div>    
                </div>    
        </Col>
       </Row>
    </div>
        <Footer/>
        </>
  )
}

export default Billing
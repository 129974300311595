import React from 'react'
import Headers from '../Header/Headers'
import { Col, Row, Card, Pagination } from 'antd'
import './ManageChatbot.css'
import Footer from '../Footer/Footer'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { BaseUrl } from '../../Constants/Constant'
import { useStateValue } from '../Reducer/reducer'
import { actionTypes } from '../Reducer/stateProvider'

const ManageChatBot = () => {

    const UserID = localStorage.getItem("UserID");
    const CompanyID = localStorage.getItem("CompanyID");
    const navigate = useNavigate()
    const { Meta } = Card;
    const [Response, setResponse] = useState([]);
    const [Loading, setLoading] = useState(false)
    const [ minvalue, setminvalue ] = useState(0);
    const [maxvalue, setmaxvalue] = useState(8);

    const [
        { IsAuthenticated },
        dispatch,
    ] = useStateValue();


    const numEachPage = 8;

    const handleChange = (value, pageSize) => {
        setminvalue((value - 1) * numEachPage);
        setmaxvalue(value * numEachPage)
    };


    useEffect(() => {
        let BOTURL = `${BaseUrl}/my_chatbot`
        let body = {
            company_id :CompanyID,
            user_id: UserID
        }
        axios
            .post(BOTURL, body)
            .then((res) => {
                console.log(res.data.response, 'CardResponse')
                if(res.data.status === 200){

                    setResponse(res.data.response)
                }
                setLoading(true)
            })
            .catch((err) => {
                console.error('error')
               })
    }, [])


    const handleCard = (card) => {
        setLoading(false)
        console.log(card,"botnameeeee")
        
        window.localStorage.setItem('currentPage', 'Update')
        dispatch({
            type: actionTypes.SET_ENTEREDTYPE,
            enteredType: "Update" 
        })
        const detailsUrl = `${BaseUrl}/display_bot_details`;

        axios
          .post(detailsUrl, {
            bot_id: card.bot_id,
          })
          .then((res) => {
            console.log( res.data,'detailsRes');
            
            
            if (res.data != {}) {
              // setManageBotItems(res.data);
              dispatch({
                type: actionTypes.SET_BOTDETAILS,
                botDetails: res.data,
              });
            } else {
              dispatch({
                type: actionTypes.SET_BOTDETAILS,
                botDetails: {},
              });
            }
            navigate(`/chatbot-builder/${res.data.bot_type}/${card.bot_id}`)
          })
           .catch((err) => {
    console.error('error')
   })

        console.log(card, "card")
        localStorage.setItem("BotID", card);
       



    }
    useEffect(() => {
        if (!IsAuthenticated || IsAuthenticated == "false") {
            navigate(`/`)
        }
    }, [IsAuthenticated])


    return (
        <>
            <Headers />
            <div className='manageChatbotMain'>
                <h1 className='manageChatBotHeader'>Manage Your Precium</h1>
                <Row className='manageChatbotRow'>
                    {Loading === false ?
                    <div className="loaderDiv">
                    <img src="/loader/tail-spin.svg" className="loaderImage" />
                    </div>
                    :
<>
                    
                    {
                        Response.length != 0 ?
                            <>
                                { 
                                Response.length > 0 &&
                                    Response.slice(minvalue, maxvalue).map((item, key) => {
                                        console.log(item.chat_icon,'chat_icon')
                                    return(
                                        <Col span={6} className='manageBotCardCol' >
                                            <Card
                                                onClick={() => handleCard(item)}
                                                hoverable
                                                style={{
                                                    width: 150,
                                                }}
                                                cover={<img alt="Bot Image" src= {item.chat_icon == "" ? "/Logo/P-Logo.png" : item.chat_icon } height={180} />}
                                            >
                                                <Meta title = { item.bot_name != 'Default' ? item.bot_name : `Precium ${key+1}` }
                                                // title={'Bot' + " " + (key + 1)}
                                                />
                                            </Card>
                                        </Col>
                                    )
                                    }
                                    )
                                }
                            </>
                            :
                            <div>
                                <h2>
                                    No Bots Found
                                </h2>
                            </div>

                    }
                    </>
                }
                </Row>
                {
                    Response.length > 8 ?
                <div className='paginationClass'>
                <Pagination
                    defaultCurrent={1}
                    defaultPageSize={numEachPage}
                    onChange={handleChange}
                    total={Response.length}
                    showSizeChanger={false}
                />
                </div>
                :<></>
                }
            </div>
            <Footer />
        </>
    )
}

export default ManageChatBot
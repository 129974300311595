export const initialState = {
    sourceText : false,
    sourceFile : false,
    sourceWeb : false,
    IsAuthenticated : localStorage.getItem("IsAuthenticated"),
    enteredType : localStorage.getItem('currentPage'),
    botDetails : {},
    sourceDataText : [],
    sourceDataFile : [],
    sourceDataWebsite : [],
    sourceType : "",
    webSource: "",
    AffiliationResponse:[],
    botSettings : {},
    role: "",
    manageActiveKey: ""
}

export const actionTypes = {
    SET_SOURCETEXT : 'SET_SOURCETEXT',
    SET_SOURCEFILE : 'SET_SOURCEFILE',
    SET_SOURCEWEB : 'SET_SOURCEWEB',
    SET_ISAUTHENTICATED : 'SET_ISAUTHENTICATED',
    SET_ENTEREDTYPE : 'SET_ENTEREDTYPE',
    SET_BOTDETAILS : 'SET_BOTDETAILS',
    SET_SOURCETYPE : 'SET_SOURCETYPE',
    SET_SOURCEDATATEXT : 'SET_SOURCEDATATEXT',
    SET_SOURCEDATAFILE : 'SET_SOURCEDATAFILE',
    SET_SOURCEDATAWEBSITE : 'SET_SOURCEDATAWEBSITE',
    SET_WEBSOURCE:'SET_WEBSOURCE',
    SET_AFFILIATIONRESPONSE:'SET_AFFILIATIONRESPONSE',
    SET_BOTSETTINGS : 'SET_BOTSETTINGS' ,
    SET_ROLE : 'SET_ROLE',
    SET_MANAGEACTIVEKEY : 'SET_MANAGEACTIVEKEY'
}

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.SET_SOURCETEXT:
        {
            return{
                ...state,
                sourceText: action.sourceText,
            }
        }
        case actionTypes.SET_SOURCEFILE:
            {
                return{
                    ...state,
                    sourceFile: action.sourceFile,
                }
            }
            case actionTypes.SET_SOURCEWEB:
            {
                return{
                    ...state,
                    sourceWeb: action.sourceWeb,
                }
            }
            case actionTypes.SET_ISAUTHENTICATED:
            {
                return{
                    ...state,
                    IsAuthenticated:action.IsAuthenticated
                }
            }
            case actionTypes.SET_ENTEREDTYPE:
            {
                return{
                    ...state,
                    enteredType:action.enteredType
                }
            }
            case actionTypes.SET_BOTDETAILS:
            {
                return{
                    ...state,
                    botDetails : action.botDetails
                }
            }
            case actionTypes.SET_SOURCETYPE:
            {
                return{
                    ...state,
                    sourceType : action.sourceType
                }
            }
            case actionTypes.SET_SOURCEDATATEXT:
            {
                return{
                    ...state,
                    sourceDataText : action.sourceDataText
                }
            }
            case actionTypes.SET_SOURCEDATAFILE:
            {
                return{
                    ...state,
                    sourceDataFile : action.sourceDataFile
                }
            }
            case actionTypes.SET_SOURCEDATAWEBSITE:
            {
                return{
                    ...state,
                    sourceDataWebsite : action.sourceDataWebsite
                }
            }
            case actionTypes.SET_WEBSOURCE:
                {
                    return{
                        ...state,
                        webSource:action.webSource
                    }
                }
                case actionTypes.SET_AFFILIATIONRESPONSE:
                    {
                        return{
                            ...state,
                            AffiliationResponse:action.AffiliationResponse
                        }
                    }
                case actionTypes.SET_BOTSETTINGS : 
                    {
                       return {
                            ...state,
                            botSettings : action.botSettings
                       } 
                    }
                    case actionTypes.SET_ROLE :
                        {
                            return {
                                ...state,
                                role : action.role
                            }
                        }
                        case actionTypes.SET_MANAGEACTIVEKEY :
                        {
                            return {
                                ...state,
                                manageActiveKey : action.manageActiveKey
                            }
                        }
            default:
                return state;
    }
};

export default reducer;
import React from "react";
import { Tabs, Row, Col, Button, Modal, message, notification } from "antd";
import Headers from "../Header/Headers";
import "./CreateModule.css";
import Settings from "./Settings/Settings";
import { useNavigate } from "react-router";
import Source from "./Source/Source";
import History from "../History/History";
import Footer from "../Footer/Footer";
import { useState, useEffect } from "react";
import BotPreview from "../../Preview/BotPreview";
import axios from "axios";
import { Link, useParams } from 'react-router-dom'
import { BaseUrl, WidgetUrl, contextHolder, openNotificationWithIconSuccess } from "../../Constants/Constant";
import { useStateValue } from "../Reducer/reducer";
import Analytics from "../Analytics/Analytics";
import { actionTypes } from "../Reducer/stateProvider";

const CreateModule = () => {
  const [PreviewVisible, setPreviewVisible] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [ActiveKey, setActiveKey] = useState("1");
  const [index, setindex] = useState([]);
  const [Type, setType] = useState("");
  const [manageBotItems, setManageBotItems] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [
    {
      sourceFile,
      sourceWeb,
      sourceText,
      IsAuthenticated,
      enteredType,
      manageActiveKey,
      sourceDataText,
    },
    dispatch,
  ] = useStateValue();
  let url = window.location.href;
  let BotID = url.split("/").slice(-1);
  console.log(BotID[0], "enteredType");
  const UserID = localStorage.getItem("UserID");

  let { botType } = useParams()

  console.log(botType,'chatbot_display_files')

  useEffect(() => {
    let UploadBotUrl = `${BaseUrl}/bot_details`;
    let BotBody = {
      user_id: UserID,
      bot_id: BotID[0],
    };
    axios.post(UploadBotUrl, BotBody).then((res) => {
      console.log(res, "ress");
    })
      .catch((err) => {
        console.error('error')
      })
  }, []);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])
  


  useEffect(() => {
    const detailsUrl = `${BaseUrl}/display_bot_details`;


    let body = {
      bot_id: BotID[0]
    }

    axios.post(detailsUrl, body)

      .then((res) => {

        if (res.data != {}) {
          // setManageBotItems(res.data);
          // setBotSettings(res.data);
          dispatch({
            type: actionTypes.SET_BOTSETTINGS,
            botSettings: res.data,
          });
        } else {
          // setBotSettings({});
          dispatch({
            type: actionTypes.SET_BOTSETTINGS,
            botSettings: {},
          });
        }

      })
      .catch((err) => {
        console.error('error')
      })


  }, [])

  useEffect(() => {
    const sourceUrl = `${BaseUrl}/chatbot_display_files`;

    axios.post(sourceUrl, { bot_id: BotID[0] })
      .then((res) => {
        let response = res.data;
        console.log(res.data, "sourceUrlRes");

        if (response.status == 200) {

          let fileDetails = response.file_details;

          const websiteFiltered = fileDetails.filter(item => item.file_type == 'website');
          const fileFiltered = fileDetails.filter(item => item.file_type == 'file');
          const textFiltered = fileDetails.filter(item => item.file_type == 'text');

          console.log(textFiltered, 'websiteFiltered')

          if (textFiltered.length > 0) {
            dispatch({
              type: actionTypes.SET_SOURCEDATATEXT,
              sourceDataText: textFiltered[0].text_or_link,
            });
            dispatch({
              type: actionTypes.SET_SOURCETEXT,
              sourceText: true
            });
          } else {
            dispatch({
              type: actionTypes.SET_SOURCEDATATEXT,
              sourceDataText: [],
            });
            dispatch({
              type: actionTypes.SET_SOURCETEXT,
              sourceText: false
            });
          }

          if (fileFiltered.length > 0) {
            dispatch({
              type: actionTypes.SET_SOURCEDATAFILE,
              sourceDataFile: fileFiltered,
            });
            dispatch({
              type: actionTypes.SET_SOURCEFILE,
              sourceFile: true
            });
          } else {
            dispatch({
              type: actionTypes.SET_SOURCEDATAFILE,
              sourceDataFile: [],
            });
            dispatch({
              type: actionTypes.SET_SOURCEFILE,
              sourceFile: false
            });
          }

          if (websiteFiltered.length > 0) {
            dispatch({
              type: actionTypes.SET_SOURCEDATAWEBSITE,
              sourceDataWebsite: websiteFiltered,
            });
            dispatch({
              type: actionTypes.SET_SOURCEWEB,
              sourceWeb: true
            });
          } else {
            dispatch({
              type: actionTypes.SET_SOURCEDATAWEBSITE,
              sourceDataWebsite: [],
            });
            dispatch({
              type: actionTypes.SET_SOURCEWEB,
              sourceWeb: false
            });
          }
        }
        else {
          dispatch({
            type: actionTypes.SET_SOURCEDATATEXT,
            sourceDataText: [],
          });
          dispatch({
            type: actionTypes.SET_SOURCEDATAFILE,
            sourceDataFile: [],
          });
          dispatch({
            type: actionTypes.SET_SOURCEDATAWEBSITE,
            sourceDataWebsite: [],
          });
          dispatch({
            type: actionTypes.SET_SOURCETEXT,
            sourceText: false
          });
          dispatch({
            type: actionTypes.SET_SOURCEFILE,
            sourceFile: false
          });
          dispatch({
            type: actionTypes.SET_SOURCEWEB,
            sourceWeb: false
          });
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, []);

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIconSuccess = (type) => {
    console.log('getsSuccess')
    api['success']({
      message: "Success!!!",
      description:
        type,
    });
  };

    const openNotificationWithIconError = (type) => {
    api['error']({
      message: "Oops!!!",
      description:
        type,
    });
  };




  console.log(
    sourceFile,
    "sourceFile",
    sourceWeb,
    "sourceWeb",
    sourceText,
    "sourceText"
  );

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk1 = () => {
    let DELETEURL = `${BaseUrl}/Delete_chatbot`;
    let body = {
      bot_id: BotID[0],
      user_id: UserID
    };
    axios.post(DELETEURL, body)
      .then((res) => {
        console.log(res, "resss");
        openNotificationWithIconSuccess(res.data.response);
        // message.success(res.data.response);
        setTimeout(() => {
          navigate("/my-chatbots");
        },[2000]);
      })
      .catch((err) => {
        console.error('error');
        openNotificationWithIconError('Error deleting Bot!!!');
      })


    setIsModalOpen1(false);


  };
  const handleCancel1 = () => {
    setIsModalOpen1(false);
  };
  useEffect(() => {
    if (!IsAuthenticated || IsAuthenticated == "false") {
      navigate(`/`);
    }
  }, [IsAuthenticated]);

  const onChange = (key) => {
    console.log(key);
    if (key == 1) {
      setPreviewVisible(true);
      setActiveKey("1");
      console.log("true");
    }
    if (key == 2) {
      setActiveKey("2");
    }
    if (key == 4) {
      setIsModalOpen(true);
      setActiveKey(ActiveKey);
    }
    if (key == 3) {
      setActiveKey("3");
    }
    if (key == 6) {
      setIsModalOpen1(true);
      setActiveKey(ActiveKey);
    }
    if (key == 5) {
      setActiveKey("5");
    } else {
      setPreviewVisible(false);
    }
  };

  useEffect(() => {
    if (sourceText || sourceWeb || sourceFile == true) {
      setActiveKey("2");
    } else if( manageActiveKey != '' ){
      setActiveKey(manageActiveKey)
    }else {
      setActiveKey(ActiveKey);
    }

    console.log(
      sourceText,
      "sourceText",
      sourceWeb,
      "sourceWeb",
      sourceFile,
      "sourceFile",
      manageActiveKey,
      'manageActiveKey'
    );
  }, [sourceFile, sourceWeb, sourceText, manageActiveKey]);

  // useEffect(() => {
  //   const detailsUrl = `${BaseUrl}/bot_full_details/`;

  //   axios
  //     .post(detailsUrl, {
  //       bot_id: BotID[0],
  //     })
  //     .then((res) => {
  //       console.log( res.data,'detailsRes');
  //       if (res.data != {}) {
  //         // setManageBotItems(res.data);
  //         dispatch({
  //           type: actionTypes.SET_BOTDETAILS,
  //           botDetails: res.data,
  //         });
  //       } else {
  //         dispatch({
  //           type: actionTypes.SET_BOTDETAILS,
  //           botDetails: {},
  //         });
  //       }
  //     });
  // }, []);

  // useEffect(() => {
  //   const sourceUrl = `${BaseUrl}/chatbot_display_files`;

  //   axios
  //     .post(sourceUrl, {
  //       bot_id: "334f77eb-0ddd-42f8-a87a-57ea0e1448ce",
  //     })
  //     .then((res) => {
  //       let response = res.data;

  //       if (response.length != 0) {
  //         dispatch({
  //           type: actionTypes.SET_SOURCEDATA,
  //           sourceData: response,
  //         });
  //       } else {
  //         dispatch({
  //           type: actionTypes.SET_SOURCEDATA,
  //           sourceData: {},
  //         });
  //       }

  //       // if(response.source_type == 'file' ){
  //       //   dispatch({
  //       //     type : actionTypes.SET_SOURCETYPE,
  //       //     sourceType : "file"
  //       //   })
  //       // }else if(response.source_type == 'text' ){
  //       //   dispatch({
  //       //     type : actionTypes.SET_SOURCETYPE,
  //       //     sourceType : "text"
  //       //   })
  //       // }else if(response.source_type == 'website' ){
  //       //   dispatch({
  //       //     type : actionTypes.SET_SOURCETYPE,
  //       //     sourceType : "website"
  //       //   })
  //       // }

  //       console.log(res, "sourceRes");
  //     });
  // }, []);

  useEffect(() => {
    console.log(enteredType, "entered");
    if (enteredType === "Create") {
      setType("Create New Precium");

      setindex([
        {
          key: "1",
          label: `Source`,
          children: <Source />,
        },
        {
          key: "2",
          label: `Settings`,
          children: <Settings />,
          // action: onclick={dontGo}
        },
        {
          key: "3",
          label: `Chat History`,
          children: <History />,
        },
        {
          key: "4",
          label: `Embed into web`,
        },
        {
          key: "5",
          label: `Analytics`,
          children: <Analytics />,
        },
      ]);
    } else {
      setType("Manage Your Precium");

      let items = [
        {
          key: "1",
          label: `Source`,
          children: <Source />,
        },
        {
          key: "2",
          label: `Settings`,
          children: <Settings />,
          // action: onclick={dontGo}
        },
        {
          key: "3",
          label: `Chat History`,
          children: <History />,
        },
        {
          key: "4",
          label: `Embed into web`,
        },

        {
          key: "5",
          label: `Analytics`,
          children: <Analytics />,
        },
        {
          key: "6",
          label: `Delete Chatbot`,
        },
      ];

      setindex(items);
    }
  }, []);
  const handleLinkClick = (event) => {
    event.preventDefault();
    window.open(event.target.href, '_blank');
  };
  const script = ` <script type="text/javascript">
   window.mychat = window.mychat || {};
   // domain for widget code embed
   window.mychat.server = '${WidgetUrl}/';
   window.mychat.iframeWidth = '400px';
   window.mychat.iframeHeight = '500px';
   window.mychat.botID = '${BotID}';
   (function() {
       var mychat = document.createElement('script'); mychat.type = 'text/javascript'; mychat.async = true;
       mychat.src = window.mychat.server + '/embed/widget.js';
       var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(mychat, s);
   })();
</script>`
  return (
    <>
    {contextHolder}
    <div>
      <Headers />

      <div className="CreatechatBot-main">
        {
          loading == true ?
            <div className="loaderDiv">
              <img src="/loader/tail-spin.svg" className="loaderImage" />
            </div>
            :
            <Row className="createChatbotRow">
              <Modal
                title="Embed into web"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <p>
                  You can test Your Chatbot here
                </p>
                <div className="embedinto">
                  {/* <Button >
             http://54.89.10.171:9003/{BotID}

              </Button> */}
                  <a href={`${WidgetUrl}/${BotID}`} onClick={handleLinkClick} target="_blank" rel="noopener noreferrer">
                    Open bot in a new Window
                  </a>
                  {/* <Link to={`http://localhost:3006/${BotID}`} ><span style={{ color: '#b670d0' }} >ChatBot</span></Link> */}

                </div>
                <p>
                  To add the Chatbot any where on your website, add this iframe to
                  your html code
                </p>
                <div className="embedinto">
                  <code>
                    &lt;iframe
                    src="{WidgetUrl}/{BotID}"
                    width="100%"
                    style="height: 100%; min-height: 700px"
                    frameborder="0"
                    &gt;&lt;/iframe&gt;

                  </code>
                </div>
                <p>
                  To add a chat bubble to the bottom right of your website add this
                  script tag to your html
                </p>
                <div className="embedinto">
                  <p> {script}</p>
                </div>
              </Modal>
              <Modal
                title="Delete Chatbot"
                okType="danger"
                open={isModalOpen1}
                onOk={handleOk1}
                onCancel={handleCancel1}
              >
                <p>
                  Are you sure you want to delete your Chatbot? This action cannot
                  be undone.
                </p>
              </Modal>
              <Col span={18} offset={3}>
                <h1 className="CreatechatBot">{Type}</h1>
                <Tabs
                  activeKey={ActiveKey}
                  tabBarStyle={{ color: "rgba(0, 0, 0, 0.5)" }}
                  className="tab"
                  defaultActiveKey="1"
                  items={index}
                  onChange={onChange}
                />
              </Col>
              <Col span={9}>
                {/* {PreviewVisible == false ? <></> :<BotPreview />} */}
              </Col>
            </Row>
        }
      </div>
      <Footer />
    </div>
    </>
  );
};

export default CreateModule;

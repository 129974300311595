import React from 'react'
import Headers from '../../Header/Headers'

const PrivacyPolicy = () => {
  return (
    <>
    <Headers />
    <div>
        Privacy Policy
    </div>
    </>
  )
}

export default PrivacyPolicy
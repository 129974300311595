// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.manageChatbotMain{
    margin-top: 50px;
    padding: 0 12.5% 5% 12.5%;
    min-height: 56.5vh;
}
.manageChatbotMain .manageChatBotHeader{
    font-weight: 500;
    /* margin-bottom: 5%; */
}
.manageBotCardCol{
    margin: 5% 0 0 0;
}
.manageChatbotRow{
    min-height: 100vh;
}
.manageBotCardCol .ant-card-meta-title{
    text-align: center;
    color: #b670d0;
    letter-spacing: 0.5px;
}
.manageBotCardCol .ant-card-body{
    padding: 14px;
}
.paginationClass{
    text-align: center;
    margin-top: 55px;
}`, "",{"version":3,"sources":["webpack://./src/Components/ManageChatBot/ManageChatbot.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,uBAAuB;AAC3B;AACA;IACI,gBAAgB;AACpB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,kBAAkB;IAClB,cAAc;IACd,qBAAqB;AACzB;AACA;IACI,aAAa;AACjB;AACA;IACI,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".manageChatbotMain{\n    margin-top: 50px;\n    padding: 0 12.5% 5% 12.5%;\n    min-height: 56.5vh;\n}\n.manageChatbotMain .manageChatBotHeader{\n    font-weight: 500;\n    /* margin-bottom: 5%; */\n}\n.manageBotCardCol{\n    margin: 5% 0 0 0;\n}\n.manageChatbotRow{\n    min-height: 100vh;\n}\n.manageBotCardCol .ant-card-meta-title{\n    text-align: center;\n    color: #b670d0;\n    letter-spacing: 0.5px;\n}\n.manageBotCardCol .ant-card-body{\n    padding: 14px;\n}\n.paginationClass{\n    text-align: center;\n    margin-top: 55px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

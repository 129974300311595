// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createSettingSave{
    margin: 6% 0 5% 0%;
    width: 100%;
    height: 38px;
    background: #b670d0;
    color: #fff;
    border-color: #b670d0;
    font-size: 17px;
}
.createSettingSave:hover{
    color: #b670d0 !important;
    background: #f3e8f7;
    border-color: #b670d7 !important;
}
.databaseSaveRow{
    margin: 5% 0%;
    display: flex !important;
    flex-flow: row wrap;
    min-width: 0;
    justify-content: space-evenly !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/NewCreateBot/NewSource/Database/Database.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,WAAW;IACX,qBAAqB;IACrB,eAAe;AACnB;AACA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,gCAAgC;AACpC;AACA;IACI,aAAa;IACb,wBAAwB;IACxB,mBAAmB;IACnB,YAAY;IACZ,wCAAwC;AAC5C","sourcesContent":[".createSettingSave{\n    margin: 6% 0 5% 0%;\n    width: 100%;\n    height: 38px;\n    background: #b670d0;\n    color: #fff;\n    border-color: #b670d0;\n    font-size: 17px;\n}\n.createSettingSave:hover{\n    color: #b670d0 !important;\n    background: #f3e8f7;\n    border-color: #b670d7 !important;\n}\n.databaseSaveRow{\n    margin: 5% 0%;\n    display: flex !important;\n    flex-flow: row wrap;\n    min-width: 0;\n    justify-content: space-evenly !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Billing-main{
    min-height: 100vh;
    margin-top: 50px;
    padding: 0px 40px;
}
.billingCardDiv{
    margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Billing/Billing.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".Billing-main{\n    min-height: 100vh;\n    margin-top: 50px;\n    padding: 0px 40px;\n}\n.billingCardDiv{\n    margin-bottom: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

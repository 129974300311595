// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createBotSetting{
    margin-top: 50px;
    min-height: 72vh;
}
.createSettingSave{
    margin: 6% 0 5% 0%;
    width: 100%;
    height: 38px;
    background: #b670d0;
    color: #fff;
    border-color: #b670d0;
    font-size: 17px;
}
.createSettingSave:hover{
    color: #b670d0 !important;
    background: #f3e8f7;
    border-color: #b670d7 !important;
}
.settingSaveRow{
    margin: 5% 0%;
}
/* .errorMessage{
    animation: trainMove 2s forwards;
}
@keyframes trainMove {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%);
    }
} */`, "",{"version":3,"sources":["webpack://./src/Components/NewCreateBot/NewSettings/NewSettings.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,WAAW;IACX,qBAAqB;IACrB,eAAe;AACnB;AACA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,gCAAgC;AACpC;AACA;IACI,aAAa;AACjB;AACA;;;;;;;;;;GAUG","sourcesContent":[".createBotSetting{\n    margin-top: 50px;\n    min-height: 72vh;\n}\n.createSettingSave{\n    margin: 6% 0 5% 0%;\n    width: 100%;\n    height: 38px;\n    background: #b670d0;\n    color: #fff;\n    border-color: #b670d0;\n    font-size: 17px;\n}\n.createSettingSave:hover{\n    color: #b670d0 !important;\n    background: #f3e8f7;\n    border-color: #b670d7 !important;\n}\n.settingSaveRow{\n    margin: 5% 0%;\n}\n/* .errorMessage{\n    animation: trainMove 2s forwards;\n}\n@keyframes trainMove {\n    0% {\n        transform: translateX(-100%);\n    }\n    100% {\n        transform: translateX(0%);\n    }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pricingMainDiv{
    min-height: 75.5vh;
    margin-top: 50px;
    padding: 0px 40px;
}
.pricingDetailsRow{
    min-height: 100vh;
}
.planHeading{
    font-weight: 500;
    margin-bottom: 15px;
    color: #b670d0;
}
.planSubHeading{
    font-weight: 400;
    font-size: 25px;
}
.planMonthspan{
    font-size: 16px;
    color: #bfbfbf;
}
.planContent{
    margin: 20px 0 0 17px;
}
.planContentCol{
    margin: 0;
}
.planContentCol ul li{
    margin-bottom: 10px;
}
.planContentCol ul li::marker{
    color: #b670d0;
    font-size: 16px;
}
.pricingCard{
    margin-bottom: 35px;
    height: 680px;
    border: 1px solid #C9C9C9;
}
.getPlanBtn{
    border: 1px solid #b670d0;
    color: #b670d0;
    margin-top: 20px;
}
.getPlanBtn:hover{
    border: 1px solid #b670d0 !important;
    background: #b670d0;
    color: #fff !important;
}
.pricingMainDiv .ant-btn-default:disabled {
    cursor: not-allowed;
    border-color: #d9d9d9;
    color: #fff;
    background-color: rgb(182 112 208);
    box-shadow: none;
    /* background: aqua; */
}`, "",{"version":3,"sources":["webpack://./src/Components/Pricing/Pricing.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;AAClB;AACA;IACI,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,eAAe;IACf,cAAc;AAClB;AACA;IACI,qBAAqB;AACzB;AACA;IACI,SAAS;AACb;AACA;IACI,mBAAmB;AACvB;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,mBAAmB;IACnB,aAAa;IACb,yBAAyB;AAC7B;AACA;IACI,yBAAyB;IACzB,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,oCAAoC;IACpC,mBAAmB;IACnB,sBAAsB;AAC1B;AACA;IACI,mBAAmB;IACnB,qBAAqB;IACrB,WAAW;IACX,kCAAkC;IAClC,gBAAgB;IAChB,sBAAsB;AAC1B","sourcesContent":[".pricingMainDiv{\n    min-height: 75.5vh;\n    margin-top: 50px;\n    padding: 0px 40px;\n}\n.pricingDetailsRow{\n    min-height: 100vh;\n}\n.planHeading{\n    font-weight: 500;\n    margin-bottom: 15px;\n    color: #b670d0;\n}\n.planSubHeading{\n    font-weight: 400;\n    font-size: 25px;\n}\n.planMonthspan{\n    font-size: 16px;\n    color: #bfbfbf;\n}\n.planContent{\n    margin: 20px 0 0 17px;\n}\n.planContentCol{\n    margin: 0;\n}\n.planContentCol ul li{\n    margin-bottom: 10px;\n}\n.planContentCol ul li::marker{\n    color: #b670d0;\n    font-size: 16px;\n}\n.pricingCard{\n    margin-bottom: 35px;\n    height: 680px;\n    border: 1px solid #C9C9C9;\n}\n.getPlanBtn{\n    border: 1px solid #b670d0;\n    color: #b670d0;\n    margin-top: 20px;\n}\n.getPlanBtn:hover{\n    border: 1px solid #b670d0 !important;\n    background: #b670d0;\n    color: #fff !important;\n}\n.pricingMainDiv .ant-btn-default:disabled {\n    cursor: not-allowed;\n    border-color: #d9d9d9;\n    color: #fff;\n    background-color: rgb(182 112 208);\n    box-shadow: none;\n    /* background: aqua; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserSettingsMainDiv{
    margin-top: 50px;
    min-height: 100vh; 
}
.AddUsers{
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 500;
    height: 35px;
    width: 148px;
    background: rgb(182, 112, 208);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(182, 112, 208);
    border-image: initial;
    float: right;
    margin-bottom: 10px;
}
.selectPlan{
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 500;
    height: 35px;
    width: 110px;
    background: rgb(182, 112, 208);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(182, 112, 208);
    border-image: initial;
  
}
.selectPlan:hover{
     color: #b670d0 !important;
    background: #f3e8f7;
    border-color: #b670d0 !important;
}
.AddUsers:hover{
    color: #b670d0 !important;
    background: #f3e8f7;
    border-color: #b670d0 !important;
}
.userSetting-main .ant-card .ant-card-body {
    padding: 4px;
    border-radius: 0 0 8px 8px;
}
.currentPlan{
    margin-left: 10px;
    margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Components/UserSettings/UserSettings.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,8BAA8B;IAC9B,iBAAiB;IACjB,mBAAmB;IACnB,gCAAgC;IAChC,qBAAqB;IACrB,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,8BAA8B;IAC9B,iBAAiB;IACjB,mBAAmB;IACnB,gCAAgC;IAChC,qBAAqB;;AAEzB;AACA;KACK,yBAAyB;IAC1B,mBAAmB;IACnB,gCAAgC;AACpC;AACA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,gCAAgC;AACpC;AACA;IACI,YAAY;IACZ,0BAA0B;AAC9B;AACA;IACI,iBAAiB;IACjB,gBAAgB;AACpB","sourcesContent":[".UserSettingsMainDiv{\n    margin-top: 50px;\n    min-height: 100vh; \n}\n.AddUsers{\n    color: rgb(255, 255, 255);\n    font-size: 16px;\n    font-weight: 500;\n    height: 35px;\n    width: 148px;\n    background: rgb(182, 112, 208);\n    border-width: 1px;\n    border-style: solid;\n    border-color: rgb(182, 112, 208);\n    border-image: initial;\n    float: right;\n    margin-bottom: 10px;\n}\n.selectPlan{\n    color: rgb(255, 255, 255);\n    font-size: 16px;\n    font-weight: 500;\n    height: 35px;\n    width: 110px;\n    background: rgb(182, 112, 208);\n    border-width: 1px;\n    border-style: solid;\n    border-color: rgb(182, 112, 208);\n    border-image: initial;\n  \n}\n.selectPlan:hover{\n     color: #b670d0 !important;\n    background: #f3e8f7;\n    border-color: #b670d0 !important;\n}\n.AddUsers:hover{\n    color: #b670d0 !important;\n    background: #f3e8f7;\n    border-color: #b670d0 !important;\n}\n.userSetting-main .ant-card .ant-card-body {\n    padding: 4px;\n    border-radius: 0 0 8px 8px;\n}\n.currentPlan{\n    margin-left: 10px;\n    margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

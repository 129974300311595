import React, { useState, useEffect } from 'react'
import Headers from '../Header/Headers'
import Footer from '../Footer/Footer'
import { Row, Col, Card, Button, message, notification } from 'antd'
import './Pricing.css'
import axios from 'axios'
import { useStateValue } from '../Reducer/reducer'
import { BaseUrl } from '../../Constants/Constant'
import { actionTypes } from '../Reducer/stateProvider';
import { useNavigate } from 'react-router-dom'

const Pricing = () => {
  const UserID = localStorage.getItem("UserID");
  const CompanyID = localStorage.getItem("CompanyID");
  const [plan, setplan] = useState("")
  const [Dynamic, setDynamic] = useState([])
  // const [messageApi, contextHolder] = message.useMessage();
  
  const navigate = useNavigate();
  const [
    { IsAuthenticated },
    dispatch,
  ] = useStateValue();

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIconSuccess = (type) => {
    console.log('getsSuccess')
    api['success']({
      message: "Success!!!",
      description:
        type,
    });
  };

const openNotificationWithIconError = (type) => {
    api['error']({
      message: "Oops!!!",
      description:
        type,
    });
  };

  useEffect(() => {
    if(IsAuthenticated === "true"){

      window.localStorage.setItem('currentPage', 'Create')

      dispatch({
        type: actionTypes.SET_ENTEREDTYPE,
        enteredType: "Create" 
      })

      dispatch({
        type : actionTypes.SET_SOURCEFILE,
        sourceFile : false
      })

      dispatch({
        type : actionTypes.SET_SOURCETEXT,
        sourceText : false
      })

      dispatch({
        type : actionTypes.SET_SOURCEWEB,
        sourceWeb : false
      })
      dispatch({
        type: actionTypes.SET_BOTDETAILS,
        botDetails: {},
      });

    

    }
    else{
      openNotificationWithIconError('Please Login to continue');
      // error('Please Login to continue');
      setTimeout(() => {
        navigate('/Login')
      }, 600)
    }
  }, [])

  // const sucess = (e) => {
  //   messageApi.open({
  //     type: 'sucess',
  //     content: e,
  //   });
  // };
  // const error = (e) => {
  //   messageApi.open({
  //     type: 'error',
  //     content: e,
  //   });
  // };


  useEffect(() => {
    let url = `${BaseUrl}/pricing_plan_dynamic`

    axios
      .post(url)
      .then((res) => {
        console.log(res.data.plan_details, "ressplan1")
        if (res.data.status === 200) {

          setDynamic(res.data.plan_details)
        }


      })
  }, [])


  useEffect(() => {
    let url = `${BaseUrl}/display_userplan_details`
    let body = {
      "company_id": CompanyID
    }
    axios
      .post(url, body)
      .then((res) => {
        setplan(res.data.plan_name)
        console.log(res, "ressplan")
      })
  }, [])


  const handleClick = ((e) => {
    console.log(e, "event")

    let url = `${BaseUrl}/select_pricing_plan?company_id=${CompanyID}&plan_name=${e[0]}&price=${e[1]}`
    axios
      .post(url)
      .then((res) => {
        console.log(res, "resss")
        if (res.data.status === 200) {
          let url = `${BaseUrl}/display_userplan_details`
          let body = {
            "company_id": CompanyID
          }
          axios
            .post(url, body)
            .then((res) => {
              if (res.data.status == 200) {

                setplan(res.data.plan_name)
                openNotificationWithIconSuccess(res.data.response);
                // sucess(res.data.response)
              } else {
                let Upgradeplan = `${BaseUrl}/upgrade_plan`
                let body = {
                  company_id: CompanyID,
                  plan_name: e[0],
                  price: e[1]
                }
                axios
                  .post(Upgradeplan, body)
                  .then((res) => {
                    if(res.data.status ==200){
                      openNotificationWithIconSuccess(res.data.response);
                      // sucess(res.data.response)

                      console.log(res,"ress")
                    }
                    else{
                      openNotificationWithIconError(res.data.response);
                      // error(res.data.response);
                    }
                  })
              }
            })

        } else {
          openNotificationWithIconError(res.data.response);
          // error(res.data.response)
        }
      })
  })
  return (
    <>
      <Headers />
      {contextHolder}
      <div className='pricingMainDiv'>
        {
          Dynamic.length != 0 ?


            <Row className='pricingDetailsRow'>
              <Col span={7}>
                <Card className='pricingCard'>
                  <h1 className='planHeading'>{Dynamic[0].plan_name}</h1>
                  <h3 className='planSubHeading'>$35<span className='planMonthspan'>/Month</span></h3>
                  <Button className='getPlanBtn' disabled={plan == "Starter" ? true : false} onClick={(e) => handleClick(['Starter', "35", e.target.textContent])} block>
                    {plan == Dynamic[0].plan_name  ? "Current plan" : "Get plan"}
                  </Button>
                  <Row className='planContent'>
                    <Col offset={2} className='planContentCol'>
                      <ul>
                        <li>{Dynamic[0].max_bots} Bot</li>
                        <li>Data sources - 2 PDF files with {Dynamic[0].max_size}MB each at max or max 400k Vectors</li>
                        <li>{Dynamic[0].max_queries_per_month} queries per month with a limitation of {Dynamic[0].max_tokens} token</li>
                        <li>No support</li>
                      </ul>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col span={7}>
                <Card className='pricingCard'>
                  <h1 className='planHeading'>{Dynamic[1].plan_name}</h1>
                  <h3 className='planSubHeading'>$625<span className='planMonthspan'>/Month</span></h3>
                  <Button className='getPlanBtn'
                    disabled={plan == "Business" ? true : false}
                    onClick={(e) => handleClick(['Business', "625", e])} block>
                    {plan == Dynamic[1].plan_name ? "Current plan" : "Get plan"}
                  </Button>
                  <Row className='planContent'>
                    <Col offset={2} className='planContentCol'>
                      <ul>
                        <li>{Dynamic[1].max_bots}  Bot</li>
                        <li>Data sources - PDF/Doc/Txt/Audio/Video with {Dynamic[1].max_size}MB with a maximum of 10 files with 5GB</li>
                        <li>{Dynamic[1].max_queries_per_month} queries  per month/per chat with a limitation of {Dynamic[1].max_tokens} token</li>
                        <li>Free of Next Version Upgrade</li>
                        <li>Removed Branding</li>
                        <li>Admin Permission to add users and assign to bot</li>
                        <li>SLA - Basic -- L1 Email Support -- 20 Hours L3 Support</li>
                      </ul>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col span={7}>
                <Card className='pricingCard'>
                  <h1 className='planHeading'>{Dynamic[2].plan_name}</h1>
                  <h3 className='planSubHeading'>$1000<span className='planMonthspan'>/Month</span></h3>
                  <Button className='getPlanBtn'
                    disabled={plan == "Corporate" ? true : false}
                    onClick={(e) => handleClick(['Corporate', "1000", e])} block>
                    {plan == Dynamic[2].plan_name ? "Current plan" : "Get plan"}
                  </Button>
                  <Row className='planContent'>
                    <Col offset={2} className='planContentCol'>
                      <ul>
                        <li>{Dynamic[2].max_bots}  Bot</li>
                        <li>Data sources - PDF/Doc/Txt/Audio/Video with 500MB with a maximum of 50 files with {Dynamic[2].max_size}GB or Max 10M Vectors</li>
                        <li>{Dynamic[2].max_queries_per_month} queries per month/per chat with a limitation of {Dynamic[2].max_tokens} token</li>
                        <li>Free of Next Version Upgrade</li>
                        <li>Removed Branding</li>
                        <li>Admin Permission to add users and assign to bot</li>
                        <li>SSO Integration</li>
                        <li>Custom Third API Integrations</li>
                        <li>SLA - Premium--1 Hour Response --16*5 Support</li>
                      </ul>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            :
            <>
            </>
        }
      </div>
      <Footer />
    </>
  )
}

export default Pricing
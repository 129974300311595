import { Button, Col, Form, Input, Row, message, notification } from "antd";
import React, { useEffect } from "react";
import "./Text.css";
import { useState } from "react";
import axios from "axios";
import { BaseUrl, MicroUrl1, MicroUrl2 } from "../../../../Constants/Constant";
import { useStateValue } from "../../../Reducer/reducer";
import { actionTypes } from "../../../Reducer/stateProvider";

const Text = () => {
  // debugger;
  const [{ sourceText, sourceDataText }, dispatch] = useStateValue();

  const { TextArea } = Input;

  console.log(sourceDataText, "sourceDataText");
  const [Text, setText] = useState(sourceDataText);
  // const [ initialValues, setInitialValues ] = useState('')
  // const [messageApi, contextHolder] = message.useMessage();

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIconSuccess = (type) => {
    console.log("getsSuccess");
    api["success"]({
      message: "Success!!!",
      description: type,
    });
  };

  const openNotificationWithIconError = (type) => {
    api["error"]({
      message: "Oops!!!",
      description: type,
    });
  };

  const openNotificationWithIconWarning = (type) => {
    api["warning"]({
      message: "Warning!!!",
      description: type,
    });
  };

  const UserID = localStorage.getItem("UserID");
  let url = window.location.href;
  let BotID = url.split("/").slice(-1);
  // const info = (val) => {
  //   messageApi.open({
  //     content: val,
  //   });
  // };
  const [loadings, setLoadings] = useState([]);
  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 60000);
  };
  const stopLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = false;
      return newLoadings;
    });
  };

  const handleSubmit = () => {
    enterLoading(0);
    let body = {
      text_content: Text,
      user_id: UserID,
      bot_id: BotID[0],
    };
    let textURL = `${MicroUrl1}/upload_text_content`;
    // let textURL = `${BaseUrl}/upload_text_content`;
    let vectorUrl = `${MicroUrl2}/vector_creation/files`;
    // let vectorUrl = `${BaseUrl}/vector_creation/files`;
    if (Text.length > 20) {
      axios
        .post(textURL, body)
        .then((res) => {
          console.log(res, "textRes");
          if (res.data.status == 200) {
            axios
              .post(vectorUrl, {
                user_id: UserID,
                bot_id: BotID[0],
                file_path: res.data.file_path,
              })
              .then((res) => {
                console.log(res, "txtres");
                openNotificationWithIconSuccess("Changes Saved sucessfully");
                // message.success("Changes Saved sucessfully");
                stopLoading(0);
                dispatch({
                  type: actionTypes.SET_SOURCETEXT,
                  sourceText: true,
                });
              });
          } else {
            openNotificationWithIconError(res.data.response);
            // message.error(res.data.response);
            stopLoading(0);
          }
          // console.log(res, "res")
        })
        .catch((err) => {
          openNotificationWithIconError("Error creating vector!");
        });
    } else {
      openNotificationWithIconWarning(
        "Text cannot be empty & Text must be atleast 20 characters!"
      );
      // message.warning("Text must be atleast 20 characters!");
      stopLoading(0);
      dispatch({
        type: actionTypes.SET_SOURCETEXT,
        sourceText: false,
      });
    }
  };

  return (
    <div>
      <Row>
        {contextHolder}
        <Col span={24}>
          {/* <div>
            <h4>Chatbot Name</h4>
            <Input
              onChange={(e) => setBotName(e.target.value)}
              className="sourceInput"
              placeholder="Enter your bot name" />
          </div> */}
          <div style={{ marginTop: "15px" }}>
            <h4>Source Text</h4>
            <Form initialValues={sourceDataText}>
              <Form.Item name="text">
                <Input.TextArea
                  value={Text}
                  defaultValue={sourceDataText}
                  onChange={(e) => setText(e.target.value)}
                  placeholder="Enter Text"
                  rows={14}
                />
              </Form.Item>
            </Form>
            {/* <TextArea
              defaultValue={Text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Enter Text"
              rows={14}
            /> */}
          </div>
        </Col>
      </Row>
      <div>
        <Button
          // disabled={!Text ? true:false}
          onClick={handleSubmit}
          loading={loadings[0]}
          className="settingSave"
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default Text;

import './App.css';
import Home from './Components/Home/Home';
import Login from './Components/Login/Login';
import Headers from './Components/Header/Headers';
import { Routes, Route} from 'react-router-dom';
import Signup from './Components/SignUp/Signup';
import ForgotPassword from './Components/ForgotPassword/ForgotPassword';
import ChatModule from './Components/CreateChatBot/CreateModule'
import CreateModule from './Components/CreateChatBot/CreateModule';
import Settings from './Components/CreateChatBot/Settings/Settings';
import Footer from './Components/Footer/Footer';
import PrivacyPolicy from './Components/Footer/TermsPolicy/PrivacyPolicy';
import TermsOfService from './Components/Footer/TermsPolicy/TermsOfService';
import ManageChatBot from './Components/ManageChatBot/ManageChatBot';
import Pricing from './Components/Pricing/Pricing';
import Account from './Components/Account/Account';
import Voice from './Components/Voice';
import Billing from './Components/Billing/Billing';
import UserSettings from './Components/UserSettings/UserSettings';
import GetbackSoon from './Components/GetbackSoon/GetbackSoon';
import Landing from './Components/Landing/Landing';
import NewCreateBot from './Components/NewCreateBot/NewCreateBot';
import Source from './Components/NewCreateBot/NewSource/Source';
import NewSettings from './Components/NewCreateBot/NewSettings/NewSetings';
import ContentEditor from './Components/ContentEditor/ContentEditor';

function App() {
  return (
    <div className="App">
         <Routes>
      <Route exact path="/Login" element={<Login/>}> </Route>
      <Route exact path="Signup" element={<Signup />}></Route>
      <Route exact path="ForgotPassword" element={<ForgotPassword />}></Route>
      <Route exact path='/' element={<Home />} />
      <Route exact path='create-type' element={<Landing />} />
      <Route exact path='privacy' element={<PrivacyPolicy />} />
      <Route exact path='terms' element={<TermsOfService />} />
      <Route exact path='my-chatbots' element={<ManageChatBot />} />
      <Route exact path='chatbot-builder/:type/:id' element={<CreateModule />} />
      <Route exact path='create-bot/:type/:id' element={<Source />} />
      <Route exact path='settings/:type/:id' element={<NewSettings />} />
      <Route exact path='pricing' element={<Pricing />} />
      <Route exact path='account' element={<Account />} />
      <Route exact path='billing' element={<Billing />} />
      <Route exact path='userSetting' element={<UserSettings/>} />
      <Route exact path='getBackSoon' element={<GetbackSoon/>} />
      <Route exact path='contenteditor' element={<ContentEditor/>} />
      </Routes>
    </div>
  );
}

export default App;

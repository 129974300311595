// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/Home/HomeSider.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.homeSiderImage img{
    float: right;
    height: 100vh;
    width: auto;
}
.homeMainClassRow{
    min-height: 100vh;
}
.homeSiderImage{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    width: auto;
    background-repeat: no-repeat;
    margin-top: -57px;
    z-index: -1;
}
.homeHeading{
    width: 316px;
    font-size: 34px;
    font-weight: 500;
    font-style: normal;
    color: #222222;
    font-family: "Kanitmedium";
}
.homeContent{
    margin-top: 86px;
}
.homeContentDesc{
    font-size: 18px;
    font-family: 'Kanit';
    font-weight: 400;
    width: 316px;
    color: #7f7f7f;
    margin-top: 22px;
}
.createChatbotBtn{
    margin: 22px 0 106px 54px;
    font-size: 20px;
    border-color: #b670d0 !important;
    height: 45px;
    width: 223px;
    background: #B670D0 !important;
    border-radius: 6px;
    font-weight: 500;
    font-style: normal;
    color: #fff !important;
}
.createChatbotBtn:hover{
    background: rgba(182, 112, 208, 0.16) !important;
    border: 1px solid #B670D0;
    color: #B670D0 !important;
    border-color: #B670D0 !important;
}
/* .homeMainClassRow{
    height: 90.6vh;
} */`, "",{"version":3,"sources":["webpack://./src/Components/Home/Home.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,WAAW;AACf;AACA;IACI,iBAAiB;AACrB;AACA;IACI,yDAA2D;IAC3D,WAAW;IACX,4BAA4B;IAC5B,iBAAiB;IACjB,WAAW;AACf;AACA;IACI,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;IACd,0BAA0B;AAC9B;AACA;IACI,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,oBAAoB;IACpB,gBAAgB;IAChB,YAAY;IACZ,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,yBAAyB;IACzB,eAAe;IACf,gCAAgC;IAChC,YAAY;IACZ,YAAY;IACZ,8BAA8B;IAC9B,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,sBAAsB;AAC1B;AACA;IACI,gDAAgD;IAChD,yBAAyB;IACzB,yBAAyB;IACzB,gCAAgC;AACpC;AACA;;GAEG","sourcesContent":[".homeSiderImage img{\n    float: right;\n    height: 100vh;\n    width: auto;\n}\n.homeMainClassRow{\n    min-height: 100vh;\n}\n.homeSiderImage{\n    background-image: url('../../../public/Home/HomeSider.svg');\n    width: auto;\n    background-repeat: no-repeat;\n    margin-top: -57px;\n    z-index: -1;\n}\n.homeHeading{\n    width: 316px;\n    font-size: 34px;\n    font-weight: 500;\n    font-style: normal;\n    color: #222222;\n    font-family: \"Kanitmedium\";\n}\n.homeContent{\n    margin-top: 86px;\n}\n.homeContentDesc{\n    font-size: 18px;\n    font-family: 'Kanit';\n    font-weight: 400;\n    width: 316px;\n    color: #7f7f7f;\n    margin-top: 22px;\n}\n.createChatbotBtn{\n    margin: 22px 0 106px 54px;\n    font-size: 20px;\n    border-color: #b670d0 !important;\n    height: 45px;\n    width: 223px;\n    background: #B670D0 !important;\n    border-radius: 6px;\n    font-weight: 500;\n    font-style: normal;\n    color: #fff !important;\n}\n.createChatbotBtn:hover{\n    background: rgba(182, 112, 208, 0.16) !important;\n    border: 1px solid #B670D0;\n    color: #B670D0 !important;\n    border-color: #B670D0 !important;\n}\n/* .homeMainClassRow{\n    height: 90.6vh;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

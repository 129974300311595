// export const BaseUrl = 'http://3.237.194.247:9001'  //Local Url
// export const BaseUrl = 'http://54.89.10.171:9001'  //UAT Url
// export const BaseUrl = 'https://api-dev.precium.ai' //DEV Url
// export const BaseUrl = 'https://api-qa.precium.ai' // QA Url
// export const BaseUrl = 'https://api-uat.precium.ai' // UAT Url
// export const BaseUrl = 'https://api-uat.precium.healthitplatform.com' // UAT Url
export const BaseUrl = 'https://uat-s1-api.healthitplatform.com' // UAT Url

//Dev Backend Microservices URL
// export const MicroUrl1 = 'https://api-dev-s1.precium.ai'
// export const MicroUrl1 = 'https://api-qa-s1.precium.ai'
// export const MicroUrl1 = 'https://api-uat-s1.precium.ai'
// export const MicroUrl1 = 'https://uat-s2-api.healthitplatform.com'
export const MicroUrl1 = 'https://uat-s1-api.healthitplatform.com'
// export const MicroUrl1 = 'http://3.237.194.247:9002'


// export const MicroUrl2 = 'https://api-dev-s2.precium.ai'
// export const MicroUrl2 = 'https://api-qa-s2.precium.ai'
// export const MicroUrl2 = 'https://api-uat-s2.precium.ai'
// export const MicroUrl2 = 'https://uat-s3-api.healthitplatform.com'
export const MicroUrl2 = 'https://uat-s1-api.healthitplatform.com'
// export const MicroUrl2 = 'http://3.237.194.247:9003'


// export const MicroUrl3 = 'https://api-dev-s3.precium.ai'
// export const MicroUrl3 = 'https://api-qa-s3.precium.ai'
// export const MicroUrl3 = 'https://api-uat-s3.precium.ai'
// export const MicroUrl3 = 'https://uat-s4-api.healthitplatform.com'
export const MicroUrl3 = 'https://uat-s1-api.healthitplatform.com'
// export const MicroUrl3 = 'http://3.237.194.247:9004'

// export const WebSocketUrl = 'wss://api-dev.precium.ai/ws'
// export const WebSocketUrl = 'wss://api-qa.precium.ai/ws'
export const WebSocketUrl = 'wss://api-uat.precium.ai/ws'

// export const WidgetUrl = 'https://widget.precium.ai'
// export const WidgetUrl = 'https://widget-qa.precium.ai'
// export const WidgetUrl = 'https://widget-uat.precium.ai'
export const WidgetUrl = 'https://uatprecium.widget.healthitplatform.com'
// export const WidgetUrl = 'http://3.237.194.247:3006'
// export const WidgetUrl = 'http://3.237.194.247:3006'

// export const REACT_APP_ACESS_KEY_ID = process.env.REACT_APP_ACESS_KEY_ID
// export const REACT_APP_SECRET_ACESS_KEY = process.env.REACT_APP_SECRET_ACESS_KEY
// export const REACT_APP_REGION = process.env.REACT_APP_REGIONn

// export const REACT_APP_ACESS_KEY_ID =  "AKIAVMTVZLEFGZ6NRQ7S" 
// export const REACT_APP_SECRET_ACESS_KEY =  "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" 
// export const REACT_APP_REGION = "us east"
export const REACT_APP_ACESS_KEY_ID = process.env.REACT_APP_ACESS_KEY_ID
export const REACT_APP_SECRET_ACESS_KEY = process.env.REACT_APP_SECRET_ACESS_KEY
export const REACT_APP_REGION = process.env.REACT_APP_REGION
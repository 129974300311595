// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sourceCol{
    width: 1000px;
    /* margin-left: 0px;
    margin-right: 0px; */
}
.sourceDiv .ant-tabs-nav{
    margin-bottom: 22px;
}
.sourceDiv .ant-tabs-top >.ant-tabs-nav::before,.sourceDiv .ant-tabs-bottom >.ant-tabs-nav::before,.sourceDiv .ant-tabs-top >div>.ant-tabs-nav::before,.sourceDiv .ant-tabs-bottom >div>.ant-tabs-nav::before{
    border-bottom: none;
}
.sourceDiv .ant-tabs .ant-tabs-ink-bar{
    background: none;
}
.sourceDiv .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    padding: 3px 24px;
    border: 1px solid #b670d0;
    border-radius: 10px;
    background: #b670d0;
    color: #fff !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/CreateChatBot/Source/Source.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb;wBACoB;AACxB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,mBAAmB;IACnB,mBAAmB;IACnB,sBAAsB;AAC1B","sourcesContent":[".sourceCol{\n    width: 1000px;\n    /* margin-left: 0px;\n    margin-right: 0px; */\n}\n.sourceDiv .ant-tabs-nav{\n    margin-bottom: 22px;\n}\n.sourceDiv .ant-tabs-top >.ant-tabs-nav::before,.sourceDiv .ant-tabs-bottom >.ant-tabs-nav::before,.sourceDiv .ant-tabs-top >div>.ant-tabs-nav::before,.sourceDiv .ant-tabs-bottom >div>.ant-tabs-nav::before{\n    border-bottom: none;\n}\n.sourceDiv .ant-tabs .ant-tabs-ink-bar{\n    background: none;\n}\n.sourceDiv .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{\n    padding: 3px 24px;\n    border: 1px solid #b670d0;\n    border-radius: 10px;\n    background: #b670d0;\n    color: #fff !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from 'react';
import { Pie, Column } from '@ant-design/plots';
import { Col, Row,Card } from 'antd';
import axios from 'axios';
import { BaseUrl } from '../../Constants/Constant';
const Analytics = () => {

  const [data, setData] = useState([]);
  const [ThumbsData, setThumbsData] = useState([])
  const [thumbsUP, setthumbsUP] = useState('')
  const [ThumbsDown, setThumbsDown] = useState("")
  const [Loading, setLoading] = useState(false)
  const [LoadingThumbs, setLoadingThumbs] = useState(false)
  let url = window.location.href;
  let BotID = url.split("/").slice(-1);
  useEffect(() => {
    let url = `${BaseUrl}/chatbot_graph_analystics`
    let body = {
      "bot_id": BotID[0]
    }
    axios
      .post(url, body)
      .then((res) => {
        if(res.data.status == 200){

          console.log(res.data, "res")
          setData(res.data)
          console.log(data, "chan")
          setLoading(true)
        }
        else{
          setLoading(true)
          console.log(res.data, "res")
          setData(res.data)
        }
      })
      .catch((err) => {
        console.error('error')
       })


  }, [])

  const DemoPie = () => {

    const config = {
      appendPadding: 10,
      data,
      angleField: 'value',
      colorField: 'type',
      radius: 0.9,
      label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
          fontSize: 14,
          textAlign: 'center',
        },
      },
      interactions: [
        {
          type: 'element-active',
        },
      ],
    };
    return <Pie {...config} />;
  };

  const currentDate = new Date();
  console.log(currentDate, "chandate")
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1; // January is 0, so we add 1
  const year = currentDate.getFullYear();
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const seconds = currentDate.getSeconds();
  const UserID = localStorage.getItem("UserID");
useEffect(() => {
 axios
 .get(`${BaseUrl}/reaction_counts?user_id=${UserID}&bot_id=${BotID[0]}`)
 .then((res) => {
  setThumbsData(res.data)
  setthumbsUP(res.data[0].sales) 
  setThumbsDown(res.data[1].sales) 
  console.log(res.data,"thumbs")
  setLoadingThumbs(true)
 })
 .catch((err) => {  
  console.log(err)
  setLoadingThumbs(true)
 })
}, [])


  const DemoColumn = () => {
 
    console.log(ThumbsData,"thumbsdata")
    const config = {
      data: ThumbsData,
      xField: 'type',
      yField: 'sales',
      label: {
        position: 'middle',
        style: {
          fill: '#FFFFFF',
          opacity: 0.6,
        },
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false,
        },
      },
      meta: {
        type: {
          alias: 'Reaction',
        },
        sales: {
          alias: 'Count',
        },
      },
    };
    return <Column {...config} />;
  };
  

  return (
    <div>
        <Row>
          <Col span={11}>
          <Card style={{padding:0}}>

          
          <Row>
            <h1>
              No of usage Analysis as of now ({day}/{month}/{year} : {hours}:{minutes})
            </h1>

          </Row>
      {Loading === true ?
         
            <DemoPie />
      
            :
            <div className="loaderDiv">
              <img src="/loader/tail-spin.svg" className="loaderImage" />
            </div>
          }
         
          </Card>
          </Col>
          <Col offset={2} span={11}>
              {
                LoadingThumbs == true ? 
            <Card>
              <>
              <h1>
                Chat Reaction
              </h1>
              <h4>
                Total Thumbs Up : <code style={{color: '#B670D0 '}}>{thumbsUP}</code>
              </h4>
              <h4>
              Total Thumbs Down : <code style={{color: '#B670D0 '}}>{ThumbsDown}</code>
              </h4>
              <div style={{marginTop:10}}>

              <DemoColumn />
              </div>
              </>
            </Card>
              :
              <div className="loaderDiv">
              <img src="/loader/tail-spin.svg" className="loaderImage" />
            </div>
}
          </Col >
        </Row>
      

    </div>
  )
}

export default Analytics
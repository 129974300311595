import React, { useEffect, useState } from "react";
import { Col, Row, Tabs } from "antd";
import './Source.css'
import Text from "./Text/Text";
import File from "./File/File";
import Website from "./Website/Website";
import Database from "./Database/Database";
import { useParams } from "react-router-dom";

const Source = () => {

  const [ items, setItems ] = useState([]);

  let { type } = useParams();

  const onChange = (key) => {
    console.log(key);
  };

  let url = window.location.href;
  let BotID = url.split("/").slice(-1);


  useEffect(() => {

    const item = [
      {
        key: "1",
        label: `Text`,
        children: <Text />
      },
      {
        key: "2",
        label: `File`,
        children: <File />,
      },
      {
        key: "3",
        label: `Website`,
        children: <Website />,
      },
      // {
      //   key: "4",
      //   label: `Database`,
      //   children: <Database />,
      // },
    ];


    if( type === 'teaching-assistant' || type === 'agreement-assistant' ){
      setItems( item.slice( 1, 2 ) )
    } else if( type === 'website-helper' ) {
      setItems( item.slice( 2, 3 ) )
    } else if( type === 'call-assistant' ){
      setItems( item.slice( 1, 3 ) )
    } else if( type === 'data-assistant' ){
      setItems( item.slice( 1, 2 ) )
    } else {
      setItems( item )
    }


  },[])


  


  

  return (
    <div className="sourceDiv">
      <Row className="sourceRow">
        <Col span={24} className="sourceCol">
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </Col>
      </Row>
    </div>
  );
};

export default Source;

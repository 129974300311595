// SocketContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import { WebSocketUrl } from '../../Constants/Constant';

const SocketContext = createContext();
const WebSocketContext = createContext();

// Custom hook to use WebSocket context
export const useWebSocketContext = () => {
  return useContext(WebSocketContext);
};

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  let url = window.location.href;
  let BotID = url.split("/").slice(-1);
  console.log(BotID[0],'botchan')

  useEffect(() => {
    const newSocket = new WebSocket(`${WebSocketUrl}/${BotID[0]}`)

    newSocket.addEventListener('open', () => {
      console.log('WebSocket connection opened');
    });

    newSocket.addEventListener('open', (event) => {
        console.log('WebSocket connection opened:', event);
      });
  
      newSocket.addEventListener('message', (event) => {
        console.log('Message from server:',JSON.parse( event.data));
        setMessage(event.data);
      });
  
      newSocket.addEventListener('error', (event) => {
        console.error('WebSocket error:', event);
        setError(event);
      });
  
      newSocket.addEventListener('close', (event) => {
        console.log('WebSocket connection closed:', event);
      });
  
      setSocket(newSocket);
  
      return () => {
        newSocket.close();
      };
  }, [url]);
  const sendMessage = (data) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(data);
    } else {
      console.error('WebSocket not connected.');
    }
  };

  const contextValue = {
    socket,
    message,
    error,
    sendMessage,
  };

  return (
    <WebSocketContext.Provider value={contextValue}>
    {children}
  </WebSocketContext.Provider>
  );
};

export const useSocket = () => {
  return useContext(SocketContext);
};

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otpInput{
    margin-top: 30px;
}
.logindiv1{
    margin: 0 auto;
    margin-top: 30vh;
}
.otpInput .otpBoxInput input{
    width: 50px !important;
    height: 50px;
    font-size: 20px;
    border-radius: 8px;
    border: 1px solid #CDCDCD;
}`, "",{"version":3,"sources":["webpack://./src/Components/ForgotPassword/ForgotPassword.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;IACI,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,sBAAsB;IACtB,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,yBAAyB;AAC7B","sourcesContent":[".otpInput{\n    margin-top: 30px;\n}\n.logindiv1{\n    margin: 0 auto;\n    margin-top: 30vh;\n}\n.otpInput .otpBoxInput input{\n    width: 50px !important;\n    height: 50px;\n    font-size: 20px;\n    border-radius: 8px;\n    border: 1px solid #CDCDCD;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import { Button, Col, Row, Steps, Tabs, notification } from "antd";
import './Source.css'
import Text from "./Text/Text";
import File from "./File/File";
import Website from "./Website/Website";
import Database from "./Database/Database";
import Headers from "../../Header/Headers";
import Footer from "../../Footer/Footer";
import './Source.css'
import { useStateValue } from "../../Reducer/reducer";
import { useNavigate, useParams } from "react-router-dom";
import { BaseUrl } from "../../../Constants/Constant";
import axios from "axios";
import { actionTypes } from "../../Reducer/stateProvider";
import { LeftOutlined } from '@ant-design/icons';
// import { LoadingOutlined, SmileOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';

const Source = () => {




    const [
        {
            sourceFile,
            sourceWeb,
            sourceText,
            IsAuthenticated,
            enteredType,
            sourceDataText,
        },
        dispatch,
    ] = useStateValue();

    let url = window.location.href;
    let BotID = url.split("/").slice(-1);

    let { type } = useParams();

    console.log(type,'botParamsType')

    const navigate = useNavigate();

    const generalItems = [
        {
            key: "1",
            label: `Text`,
            children: <Text />
        },
        {
            key: "2",
            label: `File`,
            children: <File />,
        },
        {
            key: "3",
            label: `Website`,
            children: <Website />,
        },
        // {
        //     key: "4",
        //     label: `Database`,
        //     children: <Database />,
        // },
    ];

    const [ items, setItems ] = useState([]);

    console.log(generalItems.slice(2, 3),'genralItems')

    useEffect(() => {
        if( type === 'general' ){
            setItems( generalItems );
        } else if( type === 'website-helper' ){
            setItems( generalItems.slice(2, 3) )
        }else if( type === 'call-assistant' ){
            setItems( generalItems.slice( 1, 3 ) )
        } else if( type === 'teaching-assistant' || type === 'agreement-assistant' ){
            setItems( generalItems.slice( 1, 2 ) )
        } else if( type === 'data-assistant' ){
            setItems( generalItems.slice( 1, 2 ) )
        }
    },[])

    // useEffect(() => {
    //     if (sourceText || sourceWeb || sourceFile == true) {
    //         navigate(`/settings/${BotID[0]}`)
    //     }
    //     // else {
    //     //   notification.warning('Please provide any source!')
    //     // }

    //     console.log(
    //         sourceText,
    //         "sourceText",
    //         sourceWeb,
    //         "sourceWeb",
    //         sourceFile,
    //         "sourceFile"
    //     );
    // }, [sourceFile, sourceWeb, sourceText]);


    // const UserID = localStorage.getItem("UserID");
    useEffect(() => {
        const sourceUrl = `${BaseUrl}/chatbot_display_files`;

        axios.post(sourceUrl, { bot_id: BotID[0] })
            .then((res) => {
                let response = res.data;
                console.log(res.data, "sourceUrlRes");

                if (response.status == 200) {

                    let fileDetails = response.file_details;

                    const websiteFiltered = fileDetails.filter(item => item.file_type == 'website');
                    const fileFiltered = fileDetails.filter(item => item.file_type == 'file');
                    const textFiltered = fileDetails.filter(item => item.file_type == 'text');

                    console.log(textFiltered, 'websiteFiltered')

                    if (textFiltered.length > 0) {
                        dispatch({
                            type: actionTypes.SET_SOURCEDATATEXT,
                            sourceDataText: textFiltered[0].text_or_link,
                        });
                        dispatch({
                            type: actionTypes.SET_SOURCETEXT,
                            sourceText: true
                        });

                        // navigate(`/settings/${BotID[0]}`)
                    } else {
                        dispatch({
                            type: actionTypes.SET_SOURCEDATATEXT,
                            sourceDataText: [],
                        });
                        dispatch({
                            type: actionTypes.SET_SOURCETEXT,
                            sourceText: false
                        });
                    }

                    if (fileFiltered.length > 0) {
                        dispatch({
                            type: actionTypes.SET_SOURCEDATAFILE,
                            sourceDataFile: fileFiltered,
                        });
                        dispatch({
                            type: actionTypes.SET_SOURCEFILE,
                            sourceFile: true
                        });

                        // navigate(`/settings/${BotID[0]}`)
                    } else {
                        dispatch({
                            type: actionTypes.SET_SOURCEDATAFILE,
                            sourceDataFile: [],
                        });
                        dispatch({
                            type: actionTypes.SET_SOURCEFILE,
                            sourceFile: false
                        });
                    }

                    if (websiteFiltered.length > 0) {
                        dispatch({
                            type: actionTypes.SET_SOURCEDATAWEBSITE,
                            sourceDataWebsite: websiteFiltered,
                        });
                        dispatch({
                            type: actionTypes.SET_SOURCEWEB,
                            sourceWeb: true
                        });

                        // navigate(`/settings/${BotID[0]}`)
                    } else {
                        dispatch({
                            type: actionTypes.SET_SOURCEDATAWEBSITE,
                            sourceDataWebsite: [],
                        });
                        dispatch({
                            type: actionTypes.SET_SOURCEWEB,
                            sourceWeb: false
                        });
                    }
                }
                else {
                    dispatch({
                        type: actionTypes.SET_SOURCEDATATEXT,
                        sourceDataText: [],
                    });
                    dispatch({
                        type: actionTypes.SET_SOURCEDATAFILE,
                        sourceDataFile: [],
                    });
                    dispatch({
                        type: actionTypes.SET_SOURCEDATAWEBSITE,
                        sourceDataWebsite: [],
                    });
                    dispatch({
                        type: actionTypes.SET_SOURCETEXT,
                        sourceText: false
                    });
                    dispatch({
                        type: actionTypes.SET_SOURCEFILE,
                        sourceFile: false
                    });
                    dispatch({
                        type: actionTypes.SET_SOURCEWEB,
                        sourceWeb: false
                    });
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    const UserID = localStorage.getItem("UserID");

    useEffect(() => {
        let UploadBotUrl = `${BaseUrl}/bot_details`;
        let BotBody = {
            user_id: UserID,
            bot_id: BotID[0],
        };
        axios.post(UploadBotUrl, BotBody).then((res) => {
            console.log(res, "ress");
        })
            .catch((err) => {
                console.error('error')
            })
    }, []);


    useEffect(() => {
        const detailsUrl = `${BaseUrl}/display_bot_details`;
        let body = {
            bot_id: BotID[0]
        }

        axios.post(detailsUrl, body)
            .then((res) => {
                console.log(res.data, 'botDetails');

                if (res.data != {}) {
                    // setManageBotItems(res.data);
                    // setBotSettings(res.data);
                    dispatch({
                        type: actionTypes.SET_BOTSETTINGS,
                        botSettings: res.data,
                    });
                } else {
                    // setBotSettings({});
                    dispatch({
                        type: actionTypes.SET_BOTSETTINGS,
                        botSettings: {},
                    });
                }
            })
            .catch((err) => {
                console.log(err)
            })

    }, [])




    const onChange = (key) => {
        console.log(key);
    };


    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIconWarning = (type) => {
        api['warning']({
            message: "Warning!!!",
            description:
                type,
        });
    };




    const [currentStep, setCurrentStep] = useState(1);

    const { Step } = Steps;

    const stepItem = [
        {
            title: 'Choose type'
        },
        {
            title: 'Source',
        },
        {
            title: 'Settings',
        },
    ];

    const CustomStepTitle = ({ title, onClick }) => (
        <span onClick={onClick} style={{ cursor: 'pointer' }}>
            {title}
        </span>
    );

    const handleStepClick = (stepIndex) => {
        setCurrentStep(stepIndex);
        // Here, you can add your navigation logic
        // For example, navigate to a specific URL using React Router
        switch (stepIndex) {
            case 0:
                // Navigate to the first step's URL
                navigate('/create-type');
                // Example: history.push('/step1');
                break;
            case 1:
                // Navigate to the second step's URL
                navigate(`/create-bot/${type}/${BotID[0]}`);
                // Example: history.push('/step2');
                break;
            case 2:
                // Navigate to the third step's URL
                if (sourceText || sourceWeb || sourceFile == true) {
                    navigate(`/settings/${type}/${BotID[0]}`);
                }
                else {
                    openNotificationWithIconWarning('Please provide any source!')
                    setCurrentStep(1)
                }
                // navigate(`/settings/${BotID[0]}`)
                // Example: history.push('/step3');
                break;
            default:
                break;
        }
    };

    


    return (
        <>
            <Headers />
            <div className="createBotSource">
                {contextHolder}
                <div className="sourceDiv">

                    <Row className="sourceStepRow">
                        <Col span={10}>
                            {/* <Steps items={stepItem} current={1} labelPlacement="vertical" /> */}
                            <Steps current={currentStep} labelPlacement="vertical">
                                {stepItem.map((step, index) => (
                                    <Step
                                        key={index}
                                        title={
                                            <CustomStepTitle
                                                title={step.title}
                                                onClick={() => handleStepClick(index)}
                                            />
                                        }
                                    />
                                ))}
                            </Steps>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col span={4} offset={3}>
                            <h5 onClick={() => navigate('/create-type')} ><LeftOutlined /> Back</h5>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col offset={3}>
                            <h1 className="CreatechatBot">Create New Precium - Source</h1>
                        </Col>
                    </Row>
                    <Row className="sourceRow">
                        <Col offset={3} span={18} className="sourceCol">
                            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
                        </Col>
                    </Row>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Source;
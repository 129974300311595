import React, { useEffect, useState } from 'react';

const Voice = () => {
  const [transcript, setTranscript] = useState('');

  const handleSpeechResult = (event) => {
    const { transcript } = event.results[0][0];
    console.log('Transcript:', transcript);
    setTranscript(transcript);
  };

  useEffect(() => {
    const startSpeechRecognition = () => {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = true;
      recognition.interimResults = false;
      recognition.lang = 'en-US';

      recognition.onresult = (event) => {
        handleSpeechResult(event);
      };

      recognition.onerror = (event) => {
        console.log('Speech recognition error:', event.error);
      };

      recognition.onend = () => {
        console.log('Speech recognition ended');
        startSpeechRecognition();
      };

      recognition.start();
    };

    startSpeechRecognition();
  }, []);

  return (
    <div>
      <p>Transcript: {transcript}</p>
    </div>
  );
};

export default Voice;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StateProvider } from './Components/Reducer/reducer';
import reducer, { initialState } from './Components/Reducer/stateProvider';
import Headers from './Components/Header/Headers';
import Footer from './Components/Footer/Footer';
import { SocketProvider } from './Components/Reducer/SocketContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer}>
      <BrowserRouter>
      <SocketProvider >

        <App />
      </SocketProvider>
      </BrowserRouter>
    </StateProvider>
  // </React.StrictMode>
);


reportWebVitals();
